import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { StudentDeclarationsModel, StudentProfileModel } from '@upt/erasmus/types';
import { environment } from '@upt/erasmus/shared';

const baseUrl = `${environment.apiUrl}/api/Student/`;

@Injectable()
export class StudentProfileService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    public async getOwn(): Promise<StudentProfileModel> {
        const url = `${baseUrl}GetOwn`;

        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            return res;
        } catch {
            // TODO: treat exception
            return { isEmpty: true };
        }
    }

    public async create(model: StudentProfileModel): Promise<StudentProfileModel> {
        const url = `${baseUrl}Add`;
        const result = await this.http.post<any>(url, model, await this.auth.getOptions(true))
            .toPromise();
        return result;
    }

    public async patch(data: any): Promise<any> {
        const url = `${baseUrl}Patch`;
        const result = await this.http.post<any>(url, data, await this.auth.getOptions(true)).toPromise();
        return result;
    }

    public async update(model: StudentProfileModel): Promise<StudentProfileModel> {
        let url = `${baseUrl}SaveOwn`;
        const res = await this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
        return res;
    }

    public async saveDeclarations(model: StudentDeclarationsModel): Promise<StudentDeclarationsModel> {
        const url = `${baseUrl}SaveDeclarations`;
        const res = await this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
        return res;
    }

    public async getDeclarations(): Promise<StudentDeclarationsModel> {
        const url = `${baseUrl}GetDeclarations`;
        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            if(res.length == 0) throw "Not found."
            if(res.length == 1) return res[0];
            return res;
        } catch {
            // TODO: treat exception
            return {
                declareIHaveBeenWithErasmusThisYear: false,
                numberOfDaysWithErasmus: 0,
                declareIWantGrantDisadvantagedBackgrounds: false,
                declareIWantGrantForSpecialNeeds: false,
            };
        }
    }
}
