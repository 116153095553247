import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';

import { environment } from '@upt/erasmus/shared';
import { Report } from '@upt/erasmus/types';

const baseUrl = `${environment.apiUrl}/api/`;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient,
    public auth: AuthService) { }

  //Applications
  public async getApplicationsPerMobilityType(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerMobilityType?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerCountryType(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerCountryType?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerMobilityTypeAndApplicantFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerMobilityTypeAndApplicantFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantStudiesCycle(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantStudiesCycle?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerMobilityTypeAndStudiesCycle(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerMobilityTypeAndStudiesCycle?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerStudyField(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerStudyField?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantAssociatedFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantAssociatedFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantAssociatedDepartment(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantAssociatedDepartment?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantMobilityTypeAndAssociatedFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantMobilityTypeAndAssociatedFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantMobilityTypeAndAssociatedDepartment(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantMobilityTypeAndAssociatedDepartment?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  // Duration
  public async getApplicationsPerMobilityTypeAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerMobilityTypeAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerCountryTypeAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerCountryTypeAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantFacultyAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantFacultyAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getApplicationsPerApplicantStudiesCycleAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetApplicationsPerApplicantStudiesCycleAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }
  
  //Mobilities
  public async getMobilitiesPerType(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerType?appType=${this.auth.appType}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerCountryType(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerCountryType?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerTimePeriods(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTimePeriods?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerCountry(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerCountry?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerTypeAndCountry(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTypeAndCountry?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerMobilityTypeAndApplicantFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerMobilityTypeAndApplicantFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantStudiesCycle(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantStudiesCycle?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerMobilityTypeAndStudiesCycle(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerMobilityTypeAndStudiesCycle?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerAreasOfStudyCovered(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerAreasOfStudyCovered?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantAssociatedFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantAssociatedFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantAssociatedDepartment(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantAssociatedDepartment?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerTypeAndApplicantAssociatedFaculty(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTypeAndApplicantAssociatedFaculty?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerTypeAndApplicantAssociatedDepartment(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTypeAndApplicantAssociatedDepartment?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerTeachingTypeAndApplicantAssociatedStudiesCycle(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTeachingTypeAndApplicantAssociatedStudiesCycle?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  // Duration
  public async getMobilitiesPerTypeAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerTypeAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerCountryTypeAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerCountryTypeAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantFacultyAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantFacultyAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilitiesPerApplicantStudiesCycleAndDuration(language: string, universityYearId: string, calendarYear: number) : Promise<Report>{
    const url = `${baseUrl}reports/GetMobilitiesPerApplicantStudiesCycleAndDuration?appType=${this.auth.appType}&language=${language}&universityYearId=${universityYearId}&calendarYear=${calendarYear}`;
    return this.http.get<Report>(url, await this.auth.getOptions(true)).toPromise();
  }
}
