import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(private auth: AuthService,
                private router: Router) {

    }

    public async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        if (!await this.auth.hasRole(next.data['requiredRole'])) {
            // this.messages.display('You have to be ' + next.data['requiredRole'] + ' to access this page.');
            await this.router.navigate(['/']);
            return false;
        } else {
            return true;
        }
    }
}
