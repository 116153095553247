import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from '@upt/erasmus/shared';
import { StudyAgreementModel } from 'libs/types/src/lib/erasmus/study-agreement.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { AuthService } from './auth.service';

const baseUrl = `${environment.apiUrl}/api/StudyAgreements/`;

@Injectable()
export class StudyAgreementsService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    public async getAll(universityYearId?: string): Promise<StudyAgreementModel[]> {
        let url = `${baseUrl}GetAll`;
        if (universityYearId) {
          url += `?universityYearId=${universityYearId}`;
        }

        return this.http.get<any>(url, await this.auth.getOptions(true)).pipe(map(res => {
            for (const sa of res) {
                sa.location = sa.city + ', ' + sa.country;
            }
            return res;
        })).toPromise();
    }

    public async delete(id): Promise<any> {
        const url = `${baseUrl}${id}`;
        return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
    }

    public async create(model): Promise<any> {
        const url = `${baseUrl}`;
        return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
    }

    public async update(model): Promise<any> {
        const url = `${baseUrl}`;
        return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
    }
}
