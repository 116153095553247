import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { environment } from '@upt/erasmus/shared';
import { MobilitiesFilter } from '@upt/erasmus/types';
import { FacultyModel } from 'libs/types/src/lib/erasmus/faculty.model';
import { StudyAgreementModel } from 'libs/types/src/lib/erasmus/study-agreement.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { AuthService } from './auth.service';
import { MobilityTypes } from '../../../../types/src/erasmus';

const baseUrl = `${environment.apiUrl}/api/`;

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private storage: LocalStorage
  ) { }

  public async getCountries(): Promise<any[]> {
    const url = `${baseUrl}Countries`;
    let res = await this.storage.getItem(url).toPromise();
    if (!res) {
      let countries = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      await this.storage.setItem(url, countries).toPromise();
    }
    return <any>this.storage.getItem(url).toPromise()
  }

  public async getDepartments(): Promise<FacultyModel[]> {
    const url = `${baseUrl}Auth/GetDepartments`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getProfessorsTitles(): Promise<FacultyModel[]> {
    const url = `${baseUrl}Auth/GetProfessorsTitles`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getFaculties(): Promise<FacultyModel[]> {
    const url = `${baseUrl}Faculties/GetAll`;
    let res = await this.storage.getItem(url).toPromise();
    if (!res) {
      let countries = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      await this.storage.setItem(url, countries).toPromise();
    }
    return <any>this.storage.getItem(url).toPromise()
    // return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllStaffUsers(): Promise<any[]> {
    const url = `${baseUrl}Auth/GetAllStaff`;
    let res = await this.storage.getItem(url).toPromise();
    // if (!cache) {
    //   if (!res) {
    let countries = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    await this.storage.setItem(url, countries).toPromise();
    //   }
    // }
    return <any>this.storage.getItem(url).toPromise()
    // return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllWorkUsers(): Promise<any[]> {
    const url = `${baseUrl}Auth/GetAllWorkUsers`;
    let res = await this.storage.getItem(url).toPromise();
    if (!res) {
      let countries = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      await this.storage.setItem(url, countries).toPromise();
    }
    return <any>this.storage.getItem(url).toPromise()
    // return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteUser(id): Promise<any> {
    const url = `${baseUrl}Auth/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createUser(model): Promise<any> {
    const url = `${baseUrl}Auth`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateUserRoles(model): Promise<any> {
    const url = `${baseUrl}Auth/User/Roles/${model.id}`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getStudentOwn(): Promise<any> {
    const url = `${baseUrl}Student/GetOwn`;
    try {
      const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      return res;
    } catch {
      return { isEmpty: true };
    }
  }

  public async getProfessorOwn(): Promise<any> {
    const url = `${baseUrl}Staff/Professor/GetOwn`;
    try {
      const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      return res;
    } catch {
      return { isEmpty: true };
    }
  }

  public async getAdministrativeStaffOwn(): Promise<any> {
    const url = `${baseUrl}Staff/AdministrativeStaff/GetOwn`;
    try {
      const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
      return res;
    } catch {
      return { isEmpty: true };
    }
  }

  public async saveProfessorProfile(model): Promise<any> {
    const url = `${baseUrl}Staff/Professor/SaveOwn`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async saveAdministrativeStaffProfile(model): Promise<any> {
    const url = `${baseUrl}Staff/AdministrativeStaff/SaveOwn`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }


  public async getAllMobilityDocuments(type = 'All'): Promise<any[]> {
    const url = `${baseUrl}MobilityDocuments/GetAll/${type}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteMobilityDocument(id): Promise<any> {
    const url = `${baseUrl}MobilityDocuments/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createMobilityDocument(model): Promise<any> {
    const url = `${baseUrl}MobilityDocuments`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateMobilityDocument(model): Promise<any> {
    const url = `${baseUrl}MobilityDocuments`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllTemplates(): Promise<any[]> {
    const url = `${baseUrl}Templates`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteTemplate(id): Promise<any> {
    const url = `${baseUrl}Templates/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createTemplate(model): Promise<any> {
    const url = `${baseUrl}Templates`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateTemplate(model): Promise<any> {
    const url = `${baseUrl}Templates`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllDepartments(): Promise<any[]> {
    const url = `${baseUrl}Departments/GetAll`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteDepartment(id): Promise<any> {
    const url = `${baseUrl}Departments/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createDepartment(model): Promise<any> {
    const url = `${baseUrl}Departments`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateDepartment(model): Promise<any> {
    const url = `${baseUrl}Departments`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllFieldOfStudies(): Promise<any[]> {
    const url = `${baseUrl}FieldOfStudies/GetAll`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteFieldOfStudy(id): Promise<any> {
    const url = `${baseUrl}FieldOfStudies/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createFieldOfStudy(model): Promise<any> {
    const url = `${baseUrl}FieldOfStudies`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateFieldOfStudy(model): Promise<any> {
    const url = `${baseUrl}FieldOfStudies`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllSpecializations(): Promise<any[]> {
    const url = `${baseUrl}Specializations/GetAll`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteSpecialization(id): Promise<any> {
    const url = `${baseUrl}Specializations/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createSpecialization(model): Promise<any> {
    const url = `${baseUrl}Specializations`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateSpecialization(model): Promise<any> {
    const url = `${baseUrl}Specializations`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllFaculties(): Promise<any[]> {
    const url = `${baseUrl}Faculties/GetAll`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteFaculty(id): Promise<any> {
    const url = `${baseUrl}Faculties/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createFaculty(model): Promise<any> {
    const url = `${baseUrl}Faculties`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateFaculty(model): Promise<any> {
    const url = `${baseUrl}Faculties`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllRounds(): Promise<any[]> {
    const url = `${baseUrl}Rounds/GetAll/${this.auth.appType}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteRound(id): Promise<any> {
    const url = `${baseUrl}Rounds/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getRoundById(id): Promise<any> {
    const url = `${baseUrl}Rounds/${id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createRound(model): Promise<any> {
    const url = `${baseUrl}Rounds`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateRound(model): Promise<any> {
    const url = `${baseUrl}Rounds`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getActiveUniversityYear(): Promise<any> {
    const url = `${baseUrl}UniversityYears/GetActive/${this.auth.appType}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getNextUniversityYear(): Promise<any> {
    const url = `${baseUrl}UniversityYears/GetNext/${this.auth.appType}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllUniversityYears(): Promise<any[]> {
    const url = `${baseUrl}UniversityYears/GetAll/${this.auth.appType}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteUniversityYear(id): Promise<any> {
    const url = `${baseUrl}UniversityYears/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getUniversityYearById(id): Promise<any> {
    const url = `${baseUrl}UniversityYears/${id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createUniversityYear(model): Promise<any> {
    const url = `${baseUrl}UniversityYears`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateUniversityYear(model): Promise<any> {
    const url = `${baseUrl}UniversityYears`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getActiveRound(mobilityType: MobilityTypes): Promise<any> {
    if (this.auth.appType) {
      const url = `${baseUrl}Rounds/GetActive/${this.auth.appType}/${mobilityType}`;
      return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    }
  }

  public async getActiveRoundWithAppId(mobilityType: MobilityTypes, appId): Promise<any[]> {
    if (this.auth.appType) {
      const url = `${baseUrl}Rounds/GetActive/${this.auth.appType}/${mobilityType}/${appId}`;
      return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    }
  }

  public getAllStudyAgreements(): Observable<StudyAgreementModel[]> {
    const url = `${baseUrl}StudyAgreements/GetAll`;
    return this.http.get<any>(url).pipe(map((res: any) => {
      for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
      return res;
    }));
  }

  public async getAllAvailablesStudyAgreements(): Promise<any> {
    const url = `${baseUrl}StudyAgreements/GetAllAvailables`;
    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res;
  }

  public async getAllAvailablesUniversityInternshipAgreements(): Promise<any> {
    const url = `${baseUrl}UniversityInternshipAgreements/GetAllAvailables`;
    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res;
  }

  public async getAllAvailablesTrainingAgreements(): Promise<any> {
    const url = `${baseUrl}TrainingAgreements/GetAllAvailables`;
    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res;
  }

  public async getAllAvailablesTeachingAgreements(): Promise<any> {
    const url = `${baseUrl}TeachingAgreements/GetAllAvailables`;
    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res;
  }

  public async deleteStudyAgreements(id): Promise<any> {
    const url = `${baseUrl}StudyAgreements/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createStudyAgreements(model): Promise<any> {
    const url = `${baseUrl}StudyAgreements`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateStudyAgreements(model): Promise<any> {
    const url = `${baseUrl}StudyAgreements`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllUniversityInternshipAgreements(universityYearId?: string): Promise<any[]> {
    let url = `${baseUrl}UniversityInternshipAgreements`;
    if (universityYearId) {
      url += `?universityYearId=${universityYearId}`;
    }

    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res
  }

  public async deleteUniversityInternshipAgreements(id): Promise<any> {
    const url = `${baseUrl}UniversityInternshipAgreements/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createUniversityInternshipAgreements(model): Promise<any> {
    const url = `${baseUrl}UniversityInternshipAgreements`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateUniversityInternshipAgreements(model): Promise<any> {
    const url = `${baseUrl}UniversityInternshipAgreements`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllTrainingAgreements(universityYearId?: string): Promise<any[]> {
    let url = `${baseUrl}TrainingAgreements/GetAll`;
    if (universityYearId) {
      url += `?universityYearId=${universityYearId}`;
    }

    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res
  }

  public async deleteTrainingAgreements(id): Promise<any> {
    const url = `${baseUrl}TrainingAgreements/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createTrainingAgreements(model): Promise<any> {
    const url = `${baseUrl}TrainingAgreements`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateTrainingAgreements(model): Promise<any> {
    const url = `${baseUrl}TrainingAgreements`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllTeachingAgreements(universityYearId?: string): Promise<any[]> {
    let url = `${baseUrl}TeachingAgreements/GetAll`;
    if (universityYearId) {
      url += `?universityYearId=${universityYearId}`;
    }

    let res: any = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
    for (const sa of res) { sa.location = sa.city + ', ' + sa.country; }
    return res
  }

  public async getAllLogs(): Promise<any[]> {
    const url = `${baseUrl}moderator/GetAllLogs`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteTeachingAgreements(id): Promise<any> {
    const url = `${baseUrl}TeachingAgreements/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createTeachingAgreements(model): Promise<any> {
    const url = `${baseUrl}TeachingAgreements`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateTeachingAgreements(model): Promise<any> {
    const url = `${baseUrl}TeachingAgreements`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllCommissions(): Promise<any[]> {
    const url = `${baseUrl}Commissions/GetAll/${this.auth.appType}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteCommission(id): Promise<any> {
    const url = `${baseUrl}Commissions/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getCommissionById(id): Promise<any> {
    const url = `${baseUrl}Commissions/${id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createCommission(model): Promise<any> {
    const url = `${baseUrl}Commissions`;
    model['appType'] = this.auth.appType;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateCommission(model): Promise<any> {
    const url = `${baseUrl}Commissions`;
    model['appType'] = this.auth.appType;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async saveCommissionMemberAnswer(model, commissionId): Promise<any> {
    const url = `${baseUrl}Commissions/SaveCommissionMemberAnswer/${commissionId}`;
    model['appType'] = this.auth.appType;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async requestCommissionMembersAnswers(commissionId): Promise<any> {
    const url = `${baseUrl}Commissions/RequestCommissionMembersAnswers/${commissionId}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllMobilitiesMap(filter: MobilitiesFilter): Promise<any> {
    const url = `${baseUrl}moderator/getAllMobilitiesMap/${this.auth.appType}?mobilityStatuses=${filter.mobilityStatuses}&appStatuses=${filter.applicationStatuses}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async setCommissionForMobilityMap(mobilityMapId, commission): Promise<any> {
    const url = `${baseUrl}moderator/setCommissionForMobilityMap/${mobilityMapId}`;
    return this.http.post<any>(url, commission, await this.auth.getOptions(true)).toPromise();
  }

  public async setMobilityInterview(mobilityMapId, withInterviews): Promise<any> {
    const url = `${baseUrl}moderator/setMobilityInterview/${mobilityMapId}/${withInterviews}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllDeclarationsForMobilityMap(mobilityMapId): Promise<any[]> {
    const url = `${baseUrl}moderator/getAllDeclarationsForMobilityMap/${mobilityMapId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateDeclarationsForMobilityMap(mobilityMapId): Promise<any[]> {
    const url = `${baseUrl}moderator/generateDeclarationsForMobilityMap/${mobilityMapId}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async generateDeclarationsForStaffMobilityMap(mobilityMapId, comissionMemberIds): Promise<any[]> {
    const url = `${baseUrl}moderator/generateDeclarationsForStaffMobilityMap/${mobilityMapId}`;
    return this.http.post<any>(url, comissionMemberIds, await this.auth.getOptions(true)).toPromise();
  }

  public async saveMobilityMapDeclarations(mobilityMapId, declaration): Promise<any[]> {
    const url = `${baseUrl}moderator/saveMobilityMapDeclarations/${mobilityMapId}`;
    return this.http.post<any>(url, declaration, await this.auth.getOptions(true)).toPromise();
  }

  public async getAllDeclarationsForContestation(contestationId): Promise<any[]> {
    const url = `${baseUrl}moderator/getAllDeclarationsForContestation/${contestationId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateDeclarationsForContestation(contestationId, comissionMemberIds): Promise<any[]> {
    const url = `${baseUrl}moderator/generateDeclarationsForContestation/${contestationId}`;
    return this.http.post<any>(url, comissionMemberIds, await this.auth.getOptions(true)).toPromise();
  }

  public async saveContestationDeclarations(contestationId, declaration): Promise<any[]> {
    const url = `${baseUrl}moderator/saveContestationDeclarations/${contestationId}`;
    return this.http.post<any>(url, declaration, await this.auth.getOptions(true)).toPromise();
  }

  public async saveContestationEvaluation(contestationId, evaluation): Promise<any[]> {
    const url = `${baseUrl}Applications/saveContestationEvaluation/${contestationId}`;
    return this.http.post<any>(url, evaluation, await this.auth.getOptions(true)).toPromise();
  }

  public async getMeetingByMobilityMapId(mobilityMapId): Promise<any[]> {
    const url = `${baseUrl}Meetings/getByMobilityMapId/${mobilityMapId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMeetingByContestationId(contestationId): Promise<any[]> {
    const url = `${baseUrl}Meetings/getMeetingByContestationId/${contestationId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createChatForMobilityMap(commission): Promise<any> {
    const url = `${baseUrl}Chat/CreateForMobilityMap/${commission.id}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getMessagesForMobility(mobility): Promise<any> {
    const url = `${baseUrl}Chat/GetMobilityChat/${mobility.id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createChatForContestation(commission): Promise<any> {
    const url = `${baseUrl}Chat/CreateForContestation/${commission.id}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getMessagesForContestation(contestation): Promise<any> {
    const url = `${baseUrl}Chat/GetContestationChat/${contestation.id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async addChatMessage(body: { authUser, message, chatId }): Promise<any> {
    const url = `${baseUrl}Chat/AddChatMessage`;
    return this.http.post<any>(url, body, await this.auth.getOptions(true)).toPromise();
  }

  public async archiveChat(chatId): Promise<any> {
    const url = `${baseUrl}Chat/ArchiveChat/${chatId}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async deleteMeeting(id): Promise<any> {
    const url = `${baseUrl}Meetings/${id}`;
    return this.http.delete<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMeetingById(id): Promise<any> {
    const url = `${baseUrl}Meetings/${id}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createMeeting(model): Promise<any> {
    const url = `${baseUrl}Meetings`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async updateMeeting(model): Promise<any> {
    const url = `${baseUrl}Meetings`;
    return this.http.put<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async setMobilityMapMeeting(model, mobilityMapId): Promise<any> {
    const url = `${baseUrl}Meetings/setMobilityMapMeeting/${mobilityMapId}`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async setContestationMeeting(model, contestationId): Promise<any> {
    const url = `${baseUrl}Meetings/setContestationMeeting/${contestationId}`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async addApplicationToActive(applicationId): Promise<any> {
    const url = `${baseUrl}Rounds/AddApplicationToActive/${applicationId}/${this.auth.appType}`;
    return this.http.post<any>(url, { appType: this.auth.appType }, await this.auth.getOptions(true)).toPromise();
  }

  public async getOwnNotificationsFiltered(model): Promise<any> {
    const url = `${baseUrl}Notifications/GetOwnFiltered`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getOwnNotifications(): Promise<any> {
    const url = `${baseUrl}Notifications/GetOwn`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async markAllNotificationsAsRead(): Promise<any> {
    const url = `${baseUrl}Notifications/MarkAllAsRead`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async markNotificationAsRead(id): Promise<any> {
    const url = `${baseUrl}Notifications/MarkAsRead/${id}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async upload(formData: FormData): Promise<any> {
    const url = `${baseUrl}File/Upload`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async evaluateApplication(applicationId, applicationEvaluation, bonusEvaluation, bonusEvaluationSpecialNeeds): Promise<any> {
    const url = `${baseUrl}Applications/EvaluateApplication/${applicationId}?bonusEvaluation=${bonusEvaluation}&bonusEvaluationSpecialNeeds=${bonusEvaluationSpecialNeeds}`;
    return this.http.post<any>(url, applicationEvaluation, await this.auth.getOptions(true)).toPromise();
  }

  public async saveEvaluations(mobilityMapId): Promise<any> {
    const url = `${baseUrl}moderator/SaveEvaluations/${mobilityMapId}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilityMapApplications(mobilityMapId): Promise<any> {
    const url = `${baseUrl}moderator/getMobilityMapApplications/${mobilityMapId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilityMapApplicationsOrderedByScore(agreementId): Promise<any> {
    const url = `${baseUrl}moderator/getMobilityMapApplicationsOrderedByScore/${agreementId}`;

    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getMobilityMapApplicationsInReserveStaff(applicationId, agreementId): Promise<any> {
    const url = `${baseUrl}moderator/getMobilityMapApplicationsInReserveStaff/${agreementId}/${applicationId}`;

    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  // Document generations calls
  public async getApplicationsEvaluationsResults(mobilityMapId): Promise<any> {
    const url = `${baseUrl}moderator/getApplicationsEvaluationsResults/${mobilityMapId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateDeclarationForCommmissionMemberPdf(declarationId): Promise<any> {
    const url = `${baseUrl}pdf/GenerateDeclarationForCommmissionMemberPdf?declarationId=${declarationId}&token=${await this.auth.getToken()}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateSelectionReportForMobilityMapPdf(mobilityMapId): Promise<any> {
    const url = `${baseUrl}pdf/GenerateSelectionReportForMobilityMapPdf?mobilityMapId=${mobilityMapId}&token=${await this.auth.getToken()}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateEvaluationsReportForMobilityMapPdf(mobilityMapId): Promise<any> {
    const url = `${baseUrl}pdf/GenerateEvaluationsReportForMobilityMapPdf?mobilityMapId=${mobilityMapId}&token=${await this.auth.getToken()}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateFinalDocumentForMobilityMapPdf(mobilityMapId): Promise<any> {
    const url = `${baseUrl}pdf/GenerateFinalDocumentForMobilityMapPdf?mobilityMapId=${mobilityMapId}&token=${await this.auth.getToken()}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async generateFinalDocumentForStaffMobilityMapPdf(mobilityMapId, comissionMemberIds): Promise<any> {
    const url = `${baseUrl}pdf/GenerateFinalDocumentForStaffMobilityMapPdf?mobilityMapId=${mobilityMapId}&token=${await this.auth.getToken()}`;
    return this.http.post<any>(url, comissionMemberIds, await this.auth.getOptions(true)).toPromise();
  }

  public async saveLumpSum(model): Promise<any> {
    const url = `${baseUrl}Applications/UpdateLumpSum`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async computeLumpSum(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/ComputeLumpSum/${applicationId}`;
    return this.http.post<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getVisibleMobilityDocuments(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/GetVisibleMobilityDocuments/${applicationId}`;
    return this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
  }

  public async changeDocumentStatus(model): Promise<any> {
    const url = `${baseUrl}Applications/ChangeDocumentStatus`;
    return this.http.post<any>(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async uploadMobilityDocument(formData: FormData): Promise<any> {
    const url = `${baseUrl}Applications/UploadMobilityDocument`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async uploadSupportingDocument(formData: FormData): Promise<any> {
    const url = `${baseUrl}Applications/UploadSupportingDocument`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async addFinancialContract(model: any): Promise<any> {
    const url = `${baseUrl}Applications/AddFinancialContract`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async getAvailableRequests(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/GetAvailableRequests/${applicationId}`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getRequests(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/GetRequests/${applicationId}`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async createRequest(formData: FormData): Promise<any> {
    const url = `${baseUrl}Applications/CreateRequest`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async cancelRequest(requestId: any): Promise<any> {
    const url = `${baseUrl}Applications/CancelRequest/${requestId}`;
    return this.http.post(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async changeToNextStage(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/ChangeApplicationStage`;
    return this.http.post(url, { ApplicationId: applicationId, NextStage: true, SpecificStage: 'None' }, await this.auth.getOptions(true)).toPromise();
  }

  public async createContestation(formData: FormData): Promise<any> {
    const url = `${baseUrl}Applications/CreateContestation`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async cancelContestation(contestationId: any): Promise<any> {
    const url = `${baseUrl}Applications/CancelContestation/${contestationId}`;
    return this.http.post(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async saveContestationResolution(contestationId: any, mobilityType: string, contestationEvaluationsIds): Promise<any> {
    const url = `${baseUrl}Applications/saveContestationResolution/${contestationId}?mobilityType=${mobilityType}`;
    return this.http.post(url, contestationEvaluationsIds, await this.auth.getOptions(true)).toPromise();
  }

  public async getContestations(applicationId): Promise<any> {
    const url = `${baseUrl}Applications/GetContestations/${applicationId}`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async approveRequest(model: any): Promise<any> {
    const url = `${baseUrl}moderator/ApproveRequest`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async approveWaiverRequest(model: any): Promise<any> {
    const url = `${baseUrl}moderator/ApproveWaiverRequest`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async approveRequestByHeadDri(model: any): Promise<any> {
    const url = `${baseUrl}moderator/ApproveRequestByHeadDri`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async approveWithAddendum(model: any): Promise<any> {
    const url = `${baseUrl}moderator/ApproveWithAddendum`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async rejectRequest(model: any): Promise<any> {
    const url = `${baseUrl}moderator/RejectRequest`;
    return this.http.post(url, { requestId: model.request.id, message: model.message }, await this.auth.getOptions(true)).toPromise();
  }

  public async requestReviewNeeded(model: any): Promise<any> {
    const url = `${baseUrl}moderator/RequestReviewNeeded/${model.request.id}`;
    return this.http.post(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async getOwnStudentLanguageSkills(): Promise<any> {
    const url = `${baseUrl}LanguageSkills/GetOwnStudent`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getOwnProfessorLanguageSkills(): Promise<any> {
    const url = `${baseUrl}LanguageSkills/GetOwnProfessor`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async getOwnAdministrativeStaffLanguageSkills(): Promise<any> {
    const url = `${baseUrl}LanguageSkills/GetOwnAdministrativeStaff`;
    return this.http.get(url, await this.auth.getOptions(true)).toPromise();
  }

  public async saveOwnStudentLanguageSkills(model): Promise<any> {
    const url = `${baseUrl}LanguageSkills/SaveOwnStudent`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async saveOwnProfessorLanguageSkills(model): Promise<any> {
    const url = `${baseUrl}LanguageSkills/SaveOwnProfessor`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async saveOwnAdministrativeStaffLanguageSkills(model): Promise<any> {
    const url = `${baseUrl}LanguageSkills/SaveOwnAdministrativeStaff`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async previewFinancialDocumentPdf(model): Promise<any> {
    const url = `${baseUrl}pdf/PreviewFinancialDocumentPdf`;
    return this.http.post(url, model, await this.auth.getOptions(true)).toPromise();
  }

  public async changeApplicationStatus(applicationId: string, appType: string, changeModel: { newStatus: string, oldStatus: string, message: string }): Promise<void> {
    const url = `${baseUrl}moderator/ChangeApplicationStatus/${applicationId}/${appType}`;
    await this.http.post(url, changeModel, await this.auth.getOptions(true)).toPromise();
  }

  public async changeApplicationStatusStaff(applicationId: string, changeModel: { newStatus: string, oldStatus: string, message: string }): Promise<void> {
    const url = `${baseUrl}moderator/ChangeApplicationStatusStaff/${applicationId}`;
    await this.http.post(url, changeModel, await this.auth.getOptions(true)).toPromise();
  }

  public async markMessagesAsRead(applicationId: string): Promise<any> {
    const url = `${baseUrl}Messages/MarkAsRead/${applicationId}`;
    return this.http.put<any>(url, null, await this.auth.getOptions(true)).toPromise();
  }

  public async changeDocumentDeadline(documentId: string, deadline: Date): Promise<any> {
    const url = `${baseUrl}Applications/ChangeDocumentDeadLine/${documentId}`;
    return this.http.post(url, deadline, await this.auth.getOptions(true)).toPromise();
  }

  public async checkMobilityMapMeetings() {
    const url = `${baseUrl}Applications/checkMobilityMapMeetings`;
    return this.http.post(url, null, await this.auth.getOptions(true)).toPromise();
  }
}
