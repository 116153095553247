export interface Report{
    reportName: string;
    reportDescription: string;
    reportGeneratedErrorMessage: string;
    reportDatas: ReportData[];
    // Should be deleted in the future. It is used to log in browser console the exception until server provides another logger.
    internalServerError: string;
}

export interface ReportData{
    groupingCriteria: string;
    count: number;
}