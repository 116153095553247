import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { AttachmentModel, ExperienceModel } from '@upt/erasmus/types';
import { AuthService } from './auth.service';
import { environment } from '@upt/erasmus/shared';

const baseUrl = `${environment.apiUrl}/api/Attachments/`;

@Injectable({
    providedIn: 'root',
})
export class AttachmentsService {

    constructor(private http: HttpClient,
        private auth: AuthService) {
    }

    public async getAll(applicationId: string): Promise<AttachmentModel[]> {
        const url = `${baseUrl}GetAll/${applicationId}`;

        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            return res;
        } catch {
            // TODO: treat exception
            return;
        }
    }

    public async update(model: ExperienceModel[], applicationId: string): Promise<ExperienceModel[]> {
        const url = `${baseUrl}Save/${applicationId}`;
        return this.http.post<any>(url, model, await this.auth.getOptions(true)).pipe(map(res => {
            return res;
        })).toPromise();
    }

    public async getAllStaff(applicationId: string): Promise<AttachmentModel[]> {
        const url = `${baseUrl}GetAllStaff/${applicationId}`;

        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            return res;
        } catch {
            // TODO: treat exception
            return;
        }
    }

    public async updateStaff(model: ExperienceModel[], applicationId: string): Promise<ExperienceModel[]> {
        const url = `${baseUrl}SaveStaff/${applicationId}`;
        return this.http.post<any>(url, model, await this.auth.getOptions(true)).pipe(map(res => {
            return res;
        })).toPromise();
    }
}
