<!-- <ngx-loading-bar [color]="'#f00'"></ngx-loading-bar> -->

<app-header-menu></app-header-menu>

<div class="site-container container pt-5 px-5">
  <router-outlet></router-outlet>
</div>

<p-toast [baseZIndex]="999"></p-toast>
<p-confirmDialog [style]="{maxWidth: '500px'}" key="positionDialog" [baseZIndex]="10000" [closable]="false">
</p-confirmDialog>