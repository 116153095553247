import { EntityModel } from "./entity.model";
import { InternshipPurpose } from "./internship-purpose";
import { NaceModel } from "./nace.model";

export interface InternshipMobilityModel extends EntityModel {
    nameOfTheHostInstitution: string;
    addressOfTheHostInstitution: string;
    nace: NaceModel;
    numberOfEmployees: number;
    purpose: InternshipPurpose;
    languageOfTheInternship: string;
    numberOfMobilityMonthsRequested: number;
}
