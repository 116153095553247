import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard_, RoleGuard } from '@upt/erasmus/shared';
import { LandingPageComponent } from './components/core/landing-page/landing-page.component';
import { NotFoundPageComponent } from './components/core/not-found-page/not-found-page.component';

// const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./components/students/profile/profile.module').then(x => x.StudentProfileModule);
const staffProfileModule = () => import('./components/staff/profile/profile.module').then(x => x.StaffProfileModule);
const languageSkillsModule = () => import('./components/core/language-skills/language-skills.module').then(x => x.LanguageSkillsModule);
const applicationModule = () => import('./components/core/application/layout/layout.module').then(x => x.LayoutModule);
const moderatorModule = () => import('./components/moderator/layout/layout.module').then(x => x.LayoutModule);

const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
    },
    {
        path: 'profile',
        loadChildren: profileModule,
        canActivate: [AuthGuard_],
        data: { authenticated: true },
    },
    {
        path: 'language-skills',
        loadChildren: languageSkillsModule,
        canActivate: [AuthGuard_],
        data: { authenticated: true },
    },
    {
        path: 'applications',
        loadChildren: applicationModule,
        canActivate: [AuthGuard_],
        data: { authenticated: true },
    },
    {
        path: 'moderator',
        loadChildren: moderatorModule,
        canActivate: [AuthGuard_],
        data: { authenticated: true },
    },
    {
        path: 'staff-profile',
        loadChildren: staffProfileModule,
        canActivate: [AuthGuard_],
        data: { authenticated: true },
    },
    // {
    //     path: 'applications',
    //     loadChildren: () => import('../features/application/application.module').then(m => m.ApplicationModule),
    //     canActivate: [AuthGuard],
    //     data: {authenticated: true},
    // },

    // {
    //     path: '',
    //     loadChildren: () => import('../features/website/website.module').then(m => m.WebsiteModule),
    // },
    {
        path: '**',
        component: NotFoundPageComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
