import { email } from './../custom-validators/email/validator';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, environment, HttpService } from '@upt/erasmus/shared';
import * as moment from 'moment-mini';
import { MobilitiesFilter, MobilityTypes } from '@upt/erasmus/types';

@Injectable({
  providedIn: 'root'
})
export class MobilitiesService {

  public mobilities = [];
  public faculties = [];
  public users = [];
  public rounds = [];
  public activeRound: any = {};
  public loggedUser: any = {};
  public height = '400px';
  public studiesMobilities = []
  public universityInternshipMobilities = []
  public companyInternshipMobilities = []
  public trainingMobilities = []
  public companyTrainingMobilities = []
  public teachingMobilities = []
  constructor(
    private httpService: HttpService,
    private auth: AuthService
  ) { }

  public async getSession() {
    return await this.auth.getSession();
  }

  public async init(filter: MobilitiesFilter) {
    this.getDimensions();
    await this.getActiveRound()
    await Promise.all([
      this.getAllMobilitiesMap(filter),
      this.getFaculties(),
      this.getUsers(),
      this.getRounds()
    ])
    this.loggedUser = await this.getSession();
    this.adjustMobilities();
  }

  public async getAllMobilitiesMap(filter: MobilitiesFilter) {
    this.mobilities = await this.httpService.getAllMobilitiesMap(filter);
  }

  public async getFaculties() {
    this.faculties = await this.httpService.getFaculties();
  }

  public async getUsers() {
    this.users = await this.httpService.getAllWorkUsers();
  }

  public async getRounds() {
    this.rounds = await this.httpService.getAllRounds();
  }

  public async getActiveRound() {
    try { this.activeRound = await this.httpService.getActiveRound(MobilityTypes.None); } catch { }
  }

  public stepperIndexByMobilityMapStatus = {
    "SettingUpCommissions": 0,
    "SigningDeclarations": 1,
    "Meetings": 2,
    "JudgingApplications": 3,
    "JudgingApplicationsDone": 4,
    "FinalDocumentGenerated": 4
  }

  private adjustStaffComissions(mobility) {
    // const isCommissionMembersFiltered = mobility.commission.commissionMembers.filter(cm => cm.isReplacement).length != mobility.commission.commissionMembers.filter(cm => !cm.isReplacement).length;

    // if (!isCommissionMembersFiltered) {
    const applicationsOwner = mobility.applications.map(a => a?.professorProfile ?? a?.administrativeStaffProfile);
    const membersToBeReplaced = mobility.commission.commissionMembers.filter(cm => applicationsOwner.some(o => o.email == cm.authUser.email));
    //if application owner is in commission, should be replaced
    if (membersToBeReplaced.length > 0) {
      //remove memberToBeReplaced from commission members
      mobility.commission.commissionMembers = mobility.commission.commissionMembers.filter(cm => membersToBeReplaced.every(m => m.authUser.email != cm.authUser.email));
      //find user which can replace (another one with same role if exists, admin if not)
      if (mobility.commission.commissionMembers.some(cm => membersToBeReplaced.some(m => m.role == cm.role))) {
        const replacements = mobility.commission.commissionMembers.filter(cm => membersToBeReplaced.some(m => m.role == cm.role));
        mobility.commission.commissionMembers = mobility.commission.commissionMembers.filter(cm => !cm.isReplacement || (replacements.some(r => r.authUser.email == cm.authUser.email && r.role == cm.role)));
        //should not be able to edit current user replacement
        if (membersToBeReplaced.some(m => m.authUser.email == this.loggedUser.email)) {
          const currentUser = membersToBeReplaced.find(m => m.authUser.email == this.loggedUser.email);
          const currentUserReplacementIndex = mobility.commission.commissionMembers.findIndex(cm => cm.authUser.email == replacements.find(r => r.role == currentUser.role).authUser.email);
          mobility.commission.commissionMembers[currentUserReplacementIndex].isCurrentUserReplacement = true;
        }
      } else {
        const replacement = mobility.commission.commissionMembers.find(cm => cm.authUser.roles.includes('SuperAdministrator'));
        mobility.commission.commissionMembers = mobility.commission.commissionMembers.filter(cm => !cm.isReplacement || cm.authUser.email == replacement.authUser.email);
        //should not be able to edit current user replacement
        if (membersToBeReplaced.some(m => m.authUser.email == this.loggedUser.email)) {
          const currentUserReplacementIndex = mobility.commission.commissionMembers.findIndex(cm => cm.authUser.email == replacement.authUser.email);
          mobility.commission.commissionMembers[currentUserReplacementIndex].isCurrentUserReplacement = true;
        }
      }
    }
    //else just display commission without the replacements
    else {
      mobility.commission.commissionMembers = mobility.commission.commissionMembers.filter(m => !m.isReplacement);
    }

    // console.log(mobility.commission.commissionMembers)
    // let uniqueMemebers = [];
    // for (const member of mobility.commission.commissionMembers) {
    //   if(member.isReplacement && !member.isCurrentUserReplacement) continue;
    //   member['key'] = `${member.authUser.id}-${member.role}`;
    //   let existing = uniqueMemebers.find(m => m.key == member['key']);
    //   if (!existing) {
    //     uniqueMemebers.push(member);
    //   }
    // }
    // mobility.commission.commissionMembers = uniqueMemebers;
    //reorder commission members by role
    mobility.commission.commissionMembers = mobility.commission.commissionMembers
      .map((m) => {
        if (m.commissionRole == 'BureauChief') {
          m.orderByRole = 0;
        } else if (m.commissionRole == 'AdministrativeStaff') {
          m.orderByRole = 1;
        } else if (m.commissionRole == 'Vicedean') {
          m.orderByRole = 2;
        }
        return m;
      }).sort((member1, member2) => member1.orderByRole > member2.orderByRole ? 1 : member2.orderByRole > member1.orderByRole ? -1 : 0);
    console.log(mobility.commission)

    for (const cm of mobility.commission.commissionMembers) {
      cm.isReplacement = false;
    }
    // }
  }

  public adjustMobilities(comingFromEvaluatedStaff = false) {
    this.studiesMobilities = [];
    this.universityInternshipMobilities = [];
    this.companyInternshipMobilities = [];
    this.teachingMobilities = [];
    this.companyTrainingMobilities = [];
    this.trainingMobilities = [];

    for (let i = 0; i < this.mobilities.length; i++) {
      let mobility = this.mobilities[i];

      mobility['flags'] = {
        disabled_next_commissions: false,
        disabled_next_declarations: false,
        disabled_next_meetings: false,
        disabled_next_judging: false,
        selectedIndex: 0,
        isReadOnly: false,
        remainingTimeForEvaluation: null,
        withInterviews: mobility.withInterviews
      }

      mobility['flags']['selectedIndex'] = this.stepperIndexByMobilityMapStatus[mobility.status];

      //Commissions step
      if (this.auth.appType == 'ErasmusForStaff') {
        this.adjustStaffComissions(mobility);
      }

      if (mobility.commission) {
        if (mobility.commission.commissionMembers) {
          for (let j = 0; j < mobility.commission.commissionMembers.length; j++) {
            const member = mobility.commission.commissionMembers[j];
            if (member.authUser) {
              member.authUser = this.users.find(u => u.id == member.authUser.id)
            } else {
              member.authUser = { id: null }
            };

            if (member.availability != 'Da' || !member.authUser.id) {
              mobility['flags']['disabled_next_commissions'] = true;

              if (mobility.commission.commissionMembers.some(m => m.isReplacement == true && m.availability == 'Da')) {
                mobility['flags']['disabled_next_commissions'] = false;
              }
            }
          }
        }
      }
      if (!mobility["commission"]) {
        mobility['flags']['disabled_next_commissions'] = true;
        mobility["commission"] = { commissionMembers: [] };
      }

      //Signing declarations step
      if (mobility.declarations.length == 0) mobility['flags']['disabled_next_declarations'] = true;

      for (let j = 0; j < mobility.declarations.length; j++) {
        let e = mobility.declarations[j];
        if (e.conflictOfInterests != "Yes") mobility['flags']['disabled_next_declarations'] = true;
      }

      //Meetings step
      mobility['flags']['disabled_next_meetings'] = mobility.status != 'JudgingApplications' && mobility.withInterviews == "Yes";


      //Jury step
      if (this.auth.appType == 'ErasmusForStaff') {
        if (this.loggedUser.roles.includes('Moderator') || this.loggedUser.roles.includes('SuperAdministrator')) {
          mobility['flags']['disabled_judging'] = mobility.applications.some(a => a?.professorProfile?.email == this.loggedUser.email || a?.administrativeStaffProfile?.email == this.loggedUser.email);
        }
        else if (this.loggedUser.roles.includes('SuperAdministrator')) {
          mobility['flags']['disabled_judging'] = !mobility.commission.commissionMembers.some(cm => cm.authUser.id == this.loggedUser.professor.user.id);
        }
        else {
          mobility['flags']['disabled_judging'] = true;
        }
      }
      if (this.auth.appType == 'ErasmusForStudents') {
        const expectedEvaluations = mobility.commission.commissionMembers?.length - 1; //one is administrative staff and cannot evaluate
        const evaluated = mobility.applications.filter(a => !['WorkInProgress', 'Canceled'].includes(a.status)).every(a => a.applicationEvaluations.filter(e => e.evaluated == true).length == expectedEvaluations);
        mobility['flags']['disabled_judging'] = !evaluated;
      }

      mobility['flags']['disabled_next_judging'] = mobility.status != 'JudgingApplicationsDone';

      //Final document generation step
      if (['FinalDocumentGenerated', 'JudgingApplicationsDone'].includes(mobility.status)) {
        mobility['flags']['isReadOnly'] = true;
        mobility['flags']['selectedIndex'] = this.stepperIndexByMobilityMapStatus[mobility.status];
      }

      if (comingFromEvaluatedStaff) {
        mobility['flags'].selectedIndex = 3;
      }

      // Set agreement properties
      if (mobility.studyAgreement) {
        mobility['mobilityType'] = 'Studies';
        mobility['agreement'] = mobility.studyAgreement;
        mobility['agreement'].location = mobility['agreement'].city + ', ' + mobility['agreement'].country;
        this.studiesMobilities.push(mobility)
      } else if (mobility.universityInternshipAgreement) {
        mobility['mobilityType'] = 'Internship';
        mobility['internshipType'] = 'University';
        mobility['agreement'] = mobility.universityInternshipAgreement;
        mobility['agreement'].location = mobility['agreement'].city + ', ' + mobility['agreement'].country;
        this.universityInternshipMobilities.push(mobility)
      } else if (mobility.companyInternshipAgreement) {
        mobility['mobilityType'] = 'Internship';
        mobility['internshipType'] = 'Company';
        mobility['agreement'] = mobility.companyInternshipAgreement;
        this.companyInternshipMobilities.push(mobility)
      } else if (mobility.trainingAgreement) {
        mobility['mobilityType'] = 'Training';
        mobility['trainingType'] = 'University';
        mobility['agreement'] = mobility.trainingAgreement;
        mobility['agreement'].location = mobility['agreement'].city + ', ' + mobility['agreement'].country;
        this.trainingMobilities.push(mobility)
      } else if (mobility.companyTrainingAgreement) {
        mobility['mobilityType'] = 'Training';
        mobility['trainingType'] = 'Company';
        mobility['agreement'] = mobility.companyTrainingAgreement;
        mobility['agreement'].location = mobility['agreement'].city + ', ' + mobility['agreement'].country;
        this.companyTrainingMobilities.push(mobility)
      } else if (mobility.teachingAgreement) {
        mobility['mobilityType'] = 'Teaching';
        mobility['agreement'] = mobility.teachingAgreement;
        mobility['agreement'].location = mobility['agreement'].city + ', ' + mobility['agreement'].country;
        this.teachingMobilities.push(mobility)
      }

      //console.log(
      //  this.studiesMobilities,
      //  this.universityInternshipMobilities,
      //  this.companyInternshipMobilities,
      //  this.trainingMobilities,
      //  this.companyTrainingMobilities,
      //  this.teachingMobilities
      //)
    }
  }

  public getMobilityStageFormattedPeriodDates(mobility, stageType) {
    if (mobility.mobilityType == 'Studies') {
      const stage = mobility.round.stages.find(s => s.stageType == stageType);
      const stageStartDate = new Date(stage.startDate).toLocaleDateString('en-GB');
      const stageEndDate = new Date(stage.endDate).toLocaleDateString('en-GB');

      return { startDate: stageStartDate, endDate: stageEndDate };
    }
    if (mobility.mobilityType == 'Internship') {
      const roundStartDate = new Date(mobility.round.startDate).toLocaleDateString('en-GB');
      const roundEndDate = new Date(mobility.round.endDate).toLocaleDateString('en-GB');

      return { startDate: roundStartDate, endDate: roundEndDate };
    }
    if (mobility.mobilityType == 'Training' || mobility.mobilityType == 'Teaching') {
      const stage = mobility.round.stages.find(s => s.stageType == 'DepunereEvaluareAplicatii');
      const stageStartDate = new Date(stage.startDate).toLocaleDateString('en-GB');
      const stageEndDate = new Date(stage.endDate).toLocaleDateString('en-GB');

      return { startDate: stageStartDate, endDate: stageEndDate };
    }
  }

  public getDimensions() {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    this.height = (vh - 350) + 'px';
  }

  public countApplicationsInMobility(mobility) {
    var count = 0;
    for (var item of mobility) {
      count += item.applications.length;
    }
    return count;
  }

  public countTotalApplicationsWithStatusReadyForEvaluationInMobility(mobilities) {
    let count = 0;
    for (const mobility of mobilities) {
      count += mobility.applications.filter(application => application.status === 'ReadyForEvaluation').length;
    }
    return count;
  }

  public countApplicationsWithStatusReadyForEvaluation(mobilities, roundId) {
    let count = 0;
    for (const mobility of mobilities) {
      if (mobility.id.toString() === roundId.toString()) {
        count += mobility.applications.filter(application => application.status === 'ReadyForEvaluation').length;
      }
    }
    return count;
  }

  public async previewDeclarationPdf(declaration: any): Promise<boolean> {
    if (window && window.open) {
      const session = await this.auth.getSession();

      let params = new HttpParams()
        .set('declarationId', declaration.id)
        .set('token', session.token);


      let url = `${environment.apiUrl}/api/pdf/PreviewDeclarationForCommmissionMemberPdf?${params.toString()}`;
      // console.log(url);
      window.open(url, '_blank');
      return true;
    }
    return false;
  }

  public async previewMobilitySelectionReportPdf(mobility: any): Promise<boolean> {
    if (window && window.open) {
      const session = await this.auth.getSession();

      let params = new HttpParams()
        .set('mobilityMapId', mobility.id)
        .set('token', session.token);


      let url = `${environment.apiUrl}/api/pdf/PreviewSelectionReportForMobilityMapPdf?${params.toString()}`;
      // console.log(url);
      window.open(url, '_blank');
      return true;
    }
    return false;
  }


  public async evaluateApplication(applicationId, applicationEvaluation, bonusEvaluation, bonusEvaluationSpecialNeeds) {
    await this.httpService.evaluateApplication(applicationId, applicationEvaluation, bonusEvaluation, bonusEvaluationSpecialNeeds);
  }

  public async saveEvaluations(mobilityMapId) {
    await this.httpService.saveEvaluations(mobilityMapId);
  }

  public async setMobilityInterview(mobility) {
    await this.httpService.setMobilityInterview(mobility.id, mobility.flags.withInterviews);
  }
}
