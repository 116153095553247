<div style="height: calc(100vh - 150px)">
    <button *ngIf="!onlyAdministrativeStaff" mat-raised-button [routerLink]="['..']" color="primary" class="mb-2 mr-2"
        style="height: 37px;">
        <span class="mat-small">{{'BackToDashboard' | translate}} </span>
    </button>

    <ng-container *ngIf="loggedUser">
        <ng-container *ngIf="(loggedUser.roles.includes('SuperAdministrator') || loggedUser.roles.includes('Moderator')  || loggedUser.administrativeStaffHasRoles) && !onlyAdministrativeStaff">
            <mat-tab-group>
                <mat-tab [label]="'Cadre didactice'">
                    <ng-template matTabContent>
                        <ng-container [ngTemplateOutlet]="professors_table"
                            [ngTemplateOutletContext]="{users: this.professors}">
                        </ng-container>
                    </ng-template>
                </mat-tab>
                <mat-tab [label]="'Personal administrativ'">
                    <ng-template matTabContent>
                        <ng-container [ngTemplateOutlet]="administrativeStaff_table"
                            [ngTemplateOutletContext]="{users: this.administrativeStaff}">
                        </ng-container>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <ng-container *ngIf="(loggedUser.roles.includes('SuperAdministrator') || loggedUser.roles.includes('Moderator') || loggedUser.administrativeStaffHasRoles) && onlyAdministrativeStaff">
            <ng-container [ngTemplateOutlet]="administrativeStaff_table"
                [ngTemplateOutletContext]="{users: this.administrativeStaff}">
            </ng-container>
        </ng-container>
    </ng-container>
</div>


<p-blockUI [blocked]="isSaving">
    <div>
        <mat-spinner diameter="40" class="m-auto mt-2"></mat-spinner>
    </div>
</p-blockUI>

<ng-template #professors_table let-myUsers="users">
    <p-table #dt [value]="myUsers" [columns]="proffesorsColumns" styleClass="p-datatable p-datatable-sm" [autoLayout]="true"
        [scrollable]="true" [loading]="isLoading" [scrollHeight]="'flex'" [rows]="25" [totalRecords]="myUsers.length"
        [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" selectionMode="single"
        [showJumpToPageDropdown]="true" currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id"
        rowExpandMode="single">
        <ng-template pTemplate="caption">
            <div class="table-header my-0">
                <div>
                    <ng-container>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target['value'], 'contains')"
                                [placeholder]="('SearchUsers' | translate) + '...'" />
                        </span>
                    </ng-container>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th [style.width]="col.width ? col.width:'auto'" *ngFor="let col of proffesorsColumns">
                    <div class="text-center m-auto" *ngIf="col.center">
                        {{col.name}}
                    </div>
                    <ng-container *ngIf="!col.center">
                        {{col.name}}
                    </ng-container>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-e let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
                <td *ngFor="let col of proffesorsColumns" [style.width]="col.width ? col.width:'auto'">
                    <ng-container *ngIf="col.field == '#'">
                        <div class="text-center m-auto">
                            {{rowIndex + 1}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'email'">
                        <a [href]="'mailto:' + e.email">
                            {{e.email}}
                        </a>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'name'">
                        <p style="text-transform: capitalize;" class="p-0 m-0"> {{e.lastName}} {{e.firstName}} </p>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'department'">{{e?.professor?.department?.nameRo}}</ng-container>
                    <ng-container *ngIf="col.field == 'managementRole'"><b>{{e?.professor?.roles?.managementRole}}</b></ng-container>
                    <ng-container *ngIf="col.field == 'professorRole'"><b>{{e?.professor?.roles?.professorRole}}</b></ng-container>
                    <ng-container *ngIf="col.field == 'commissionRoles'"><b>{{e?.professor?.commissionRoles}}</b></ng-container>

                    <ng-container *ngIf="col.field == 'actions'">
                        <div class="text-center m-auto">
                            <button mat-mini-fab type="button" color="primary" class="ml-2 my-0" matTooltip="Edit"
                                (click)="openEditUserRolesDialog(e)">
                                <mat-icon style="font-size: 20px;">edit</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-proffesorsColumns>
            <tr>
                <td [attr.colspan]="proffesorsColumns.length" class="text-center">
                    <div class="d-flex m-auto text-center w-full">
                        <p class="w-full mb-0">
                            {{'NoRecordsFound' | translate}}
                        </p>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <p-dialog *ngIf="showEditUserRolesDialog" [header]="('EditUser') | translate"
        [(visible)]="showEditUserRolesDialog" [modal]="true" [draggable]="false"
        [style]="{width: '1000px', 'height': 'auto', 'max-height': '100%'}">
        <form #myForm="ngForm">
            <div class="m-0 grid" style="display: flex;">
                <div class="col-4">
                    <mat-form-field class="full-width" autocomplete="off">
                        <mat-select placeholder="ProfessorRole" [(ngModel)]="activeUser?.professor.roles.professorRole"
                            name="professorRole">
                            <mat-option *ngFor="let option of professorRoles" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="full-width" autocomplete="off">
                        <mat-select placeholder="ManagementRole" [(ngModel)]="activeUser?.professor.roles.managementRole"
                            name="managementRole">
                            <mat-option *ngFor="let option of managementRoles" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="full-width" autocomplete="off">
                        <mat-select placeholder="CommissionRoles" [(ngModel)]="activeUser?.professor.commissionRoles" [disabled]="true"
                            name="commissionRoles" multiple>
                            <mat-option *ngFor="let option of commissionRoles" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <button type="button" class="mr-2" color="primary" mat-stroked-button (click)="closeEditUserRolesDialog()">
                {{'Cancel' | translate}}
            </button>
            <button type="button" color="primary" mat-raised-button (click)="save(activeUser)" [disabled]="!myForm.form.valid">
                {{'Save' | translate}}
            </button>
        </ng-template>
    </p-dialog>

</ng-template>

<ng-template #administrativeStaff_table let-myUsers="users">
    <p-table #dt [value]="myUsers" [columns]="administrativeStaffColumns" styleClass="p-datatable p-datatable-sm" [autoLayout]="true"
        [scrollable]="true" [loading]="isLoading" [scrollHeight]="'flex'" [rows]="25" [totalRecords]="myUsers.length"
        [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]" selectionMode="single"
        [showJumpToPageDropdown]="true" currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id"
        rowExpandMode="single">
        <ng-template pTemplate="caption">
            <div class="table-header my-0">
                <div>
                    <ng-container>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target['value'], 'contains')"
                                [placeholder]="('SearchUsers' | translate) + '...'" />
                        </span>
                    </ng-container>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th [style.width]="col.width ? col.width:'auto'" *ngFor="let col of administrativeStaffColumns">
                    <div class="text-center m-auto" *ngIf="col.center">
                        {{col.name}}
                    </div>
                    <ng-container *ngIf="!col.center">
                        {{col.name}}
                    </ng-container>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-e let-rowIndex="rowIndex" let-expanded="expanded">
            <tr>
                <td *ngFor="let col of administrativeStaffColumns" [style.width]="col.width ? col.width:'auto'">
                    <ng-container *ngIf="col.field == '#'">
                      <div class="text-center m-auto">
                          {{rowIndex + 1}}
                      </div>
                  </ng-container>
                  <ng-container *ngIf="col.field == 'email'">
                      <a [href]="'mailto:' + e.email">
                          {{e.email}}
                      </a>
                  </ng-container>
                  <ng-container *ngIf="col.field == 'name'">
                      <p style="text-transform: capitalize;" class="p-0 m-0"> {{e.lastName}} {{e.firstName}} </p>
                  </ng-container>
                  <ng-container *ngIf="col.field == 'department'">{{e?.administrativeStaff?.department?.nameRo}}</ng-container>
                  <ng-container *ngIf="col.field == 'administrativeStaffRole'"><b>{{e?.administrativeStaff?.administrativeStaffRoles}}</b></ng-container>
                  <ng-container *ngIf="col.field == 'commissionRoles'"><b>{{e?.administrativeStaff?.commissionRoles}}</b></ng-container>

                  <ng-container *ngIf="col.field == 'actions'">
                      <div class="text-center m-auto">
                          <button mat-mini-fab type="button" color="primary" class="ml-2 my-0" matTooltip="Edit"
                              (click)="openEditUserRolesDialog(e)">
                              <mat-icon style="font-size: 20px;">edit</mat-icon>
                          </button>
                      </div>
                  </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-administrativeStaffColumns>
            <tr>
                <td [attr.colspan]="administrativeStaffColumns.length" class="text-center">
                    <div class="d-flex m-auto text-center w-full">
                        <p class="w-full mb-0">
                            {{'NoRecordsFound' | translate}}
                        </p>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-dialog *ngIf="showEditUserRolesDialog" [header]="('EditUser') | translate"
        [(visible)]="showEditUserRolesDialog" [modal]="true" [draggable]="false"
        [style]="{width: '1000px', 'height': 'auto', 'max-height': '100%'}">
        <form #myForm="ngForm">
            <div class="m-0 grid" style="display: flex;">
                <div class="col-6">
                    <mat-form-field class="full-width" autocomplete="off">
                        <mat-select placeholder="AdministrativeStaffRole" [(ngModel)]="activeUser?.administrativeStaff.administrativeStaffRoles"
                            name="administrativeStaffRole" multiple>
                            <mat-option *ngFor="let option of administrativeStaffRoles" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field class="full-width" autocomplete="off">
                      <mat-select placeholder="CommissionRoles" [(ngModel)]="activeUser?.administrativeStaff.commissionRoles" [disabled]="true"
                          name="commissionRoles" multiple>
                          <mat-option *ngFor="let option of commissionRoles" [value]="option">
                              {{option}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
            </div>
        </form>
        <ng-template pTemplate="footer">
            <button type="button" class="mr-2" color="primary" mat-stroked-button (click)="closeEditUserRolesDialog()">
                {{'Cancel' | translate}}
            </button>
            <button type="button" color="primary" mat-raised-button (click)="save(activeUser)" [disabled]="!myForm.form.valid">
                {{'Save' | translate}}
            </button>
        </ng-template>
    </p-dialog>

</ng-template>
