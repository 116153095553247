import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-mini';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public notifications = [];
  public showOnlyReadNotification = true;
  public defaultViewForOpenedNotif = true;
  public selecetedNotification = null;
  constructor(private httpService: HttpService, private router: Router) {}

  public async getOwnNotifications() {
    this.notifications = await this.httpService.getOwnNotifications();
    this.notifications = this.notifications.reverse();
    this.adjustActiveNotifications();
  }

  public async getOwnNotificationsFiltered() {
    this.notifications = await this.httpService.getOwnNotificationsFiltered({
      isRead: this.showOnlyReadNotification ? 'NotRead' : 'Any',
    });
    this.notifications = this.notifications.reverse();
    this.adjustActiveNotifications();
  }

  private adjustActiveNotifications() {
    this.notifications = this.notifications.filter((n) =>
      n.appType != 'None' ? this.httpService.auth.appType == n.appType : false
    );
    for (const notification of this.notifications) {
      notification.created = moment(notification.created).utc(true);
    }
  }

  public async markAllNotificationsAsRead() {
    await this.httpService.markAllNotificationsAsRead();
    await this.getOwnNotificationsFiltered();
  }

  public async markNotificationAsRead(id) {
    await this.httpService.markNotificationAsRead(id);
    await this.getOwnNotificationsFiltered();
  }

  public async openNotification(notification) {
    try {
      await this.httpService.markNotificationAsRead(notification.id);
      this.selecetedNotification = notification;
      this.defaultViewForOpenedNotif = true;
      if (notification.type == 'MemberForBaseCommissionsRequested') {
        let data = JSON.parse(notification.data);
        let commissionId = data.commissionId;
        await this.router.navigateByUrl(`/home`);
        this.router.navigateByUrl(
          `/moderator/commissions?commissionId=${commissionId}`
        );
      } else if (notification.type == 'SelectionCommissionMemberRequested') {
        let data = JSON.parse(notification.data);
        let id = null;
        if (data.mobilityMapId) {
          id = data.mobilityMapId;
        }
        await this.router.navigateByUrl(`/home`);
        this.router.navigateByUrl(
          `/moderator/active-mobilities?mobilityMapId=${id}`
        );
      } else if (notification.type == 'NewRequestCreated') {
        let data = JSON.parse(notification.data);
        let appId = data.applicationId
        await this.router.navigateByUrl(`/home`);
        this.router.navigateByUrl(
          `/moderator/applications?applicationId=${appId}`
        );
      } else {
        // this.selecetedNotification = notification;
        // this.defaultViewForOpenedNotif = true;
        //console.log(this.selecetedNotification, this.defaultViewForOpenedNotif);
        //
      }
    } catch (error) {
      console.error(error);
    }
  }
}
