import { ManagementRole, ProfessorRole } from './../../../../../../../libs/types/src/lib/erasmus/user.model';
import { Component, OnInit } from '@angular/core';
import { AuthService, HttpService } from '@upt/erasmus/shared';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
    selector: 'erasmus-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
    public displayAppChooserDialog = false;
    public displayGDPRDialog = false;
    public gdprAgree = false;
    public appType = null;
    public session = null;
    constructor(
        private auth: AuthService,
        private router: Router,
        private storage: LocalStorage
    ) { }

    public async ngOnInit() {
        this.redirectIfLoggedIn();
        try {
            this.session = await this.auth.refreshSession();
            await this.auth.saveSession(this.session);
        } catch (error) { }
        this.redirectIfLoggedIn();
    }

    private async redirectIfLoggedIn() {
        this.appType = null;
        if (this.auth.authState) {
            await this.redirectByRole();
        }
        this.auth.authStateChanged.subscribe(async state => {
            //console.log(state, await this.auth.getSession())
            if (state) {
                await this.redirectByRole();
            }
        });
    }

    async redirectByRole() {
        this.appType = await this.auth.getAppType();
        this.session = await this.auth.getSession();
        //console.log(this.appType);
        if (await this.auth.hasRole('Moderator')
          || await this.auth.hasRole('Professor')
          || await this.auth.hasRole(ProfessorRole[ProfessorRole.SuperAdministrator])
          || await this.auth.hasRole('AdministrativeStaff')) {

            if (!this.appType) {
                if (
                    this.session.roles.includes('Professor')
                    && !this.session.roles.includes('Rector')
                    && !this.session.roles.includes('ProrectorRi')
                ) {
                    if (!this.session.professor.involvedInErasmusStudents) {
                        this.appType = 'ErasmusForStaff';
                        await this.setAppType();
                        return;
                    }
                }
                if (!(this.session.roles.includes('Moderator')
                    || this.session.roles.includes('SuperAdministrator')
                    || this.session.roles.includes('AdministrativeStaff'))
                    && !this.session.roles.includes('Student')) {
                    if(!(this.session.professor?.isCommissionMemberErasmusForStudents
                      || this.session.administrativeStaff?.isCommissionMemberErasmusForStudents)) {
                        this.appType = 'ErasmusForStaff';
                        await this.setAppType();
                        return;
                    }
                }
                this.displayAppChooserDialog = true;
            } else {
                this.displayAppChooserDialog = false;
                if (!this.session.isVerified) {
                    this.displayGDPRDialog = true;
                } else {
                    this.displayGDPRDialog = false;
                    if (this.appType == 'ErasmusForStudents') {
                        this.router.navigate(['/', 'moderator']);
                    } else if (this.appType == 'ErasmusForStaff') {
                        this.router.navigate(['/', 'staff-profile']);
                    }
                }
            }
        } else if (await this.auth.hasRole('Student')) {
            this.displayAppChooserDialog = false;

            if (!this.session.isVerified) {
                this.displayGDPRDialog = true;
            } else {
                this.displayGDPRDialog = false;
                this.router.navigate(['/', 'profile']);
            }
        }
    }

    public async setAppType() {
        await this.storage.setItem(AuthService.appTypeStorageKey, this.appType).toPromise();
        this.auth.appType = this.appType;
        this.auth._authState.emit(!!this.auth._token);
        this.redirectByRole()
    }

    public async setGdpr() {
        await this.auth.verifyEmail(this.session.verificationToken)
        this.session = await this.auth.refreshSession();
        await this.auth.saveSession(this.session);
        this.auth._authState.emit(!!this.auth._token);
        this.redirectByRole()
        // this.session.verified = true;
    }

    public openLoginModal(): void {
        this.auth.displayLoginModal = true;
    }
}
