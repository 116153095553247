import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

import { environment } from '@upt/erasmus/shared';

const baseUrl = `${environment.apiUrl}/api/`;

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private http: HttpClient,
    public auth: AuthService) { }

  public async importTeachingAgreements(formData: FormData, universityYearId: string): Promise<any> {
    const url = `${baseUrl}Imports/ImportTeachingAgreementsFile?universityYearId=${universityYearId}`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async importTrainingAgreements(formData: FormData, universityYearId: string): Promise<any> {
    const url = `${baseUrl}Imports/ImportTrainingAgreementsFile?universityYearId=${universityYearId}`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async importStudyAgreements(formData: FormData, universityYearId: string): Promise<any> {
    const url = `${baseUrl}Imports/ImportStudyAgreementsFile?universityYearId=${universityYearId}`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }

  public async importInternshipAgreements(formData: FormData, universityYearId: string): Promise<any> {
    const url = `${baseUrl}Imports/ImportInternshipAgreementsFile?universityYearId=${universityYearId}`;
    return this.http.post(url, formData, await this.auth.getOptions(true)).toPromise();
  }
}
