<mat-toolbar color="primary" class="fixed-top mat-elevation-z6 navbar" layout="row">
    <div class="container" >
        <span class="mat-subheading-2 page-name">
            <!-- <a routerLink="/"><b>Erasmus+</b></a> -->
            <a routerLink="/">
                <img style="height: 50px;margin-left: 20px;" src="assets/images/erasmus_white.png">
            </a>
        </span>
        <span class="spacer"></span>
        <ng-container *ngIf="isLoggedIn && isVerified">
            <span *ngFor="let item of menuItems" class="mt-2">
                <ng-container>
                    <a *ngIf="item.route" [routerLink]="[item.route]" mat-button routerLinkActive="active">{{item.name |
                        translate}}
                        <span class="material-icons">
                            {{item.icon}}
                        </span>
                    </a>
                    <a *ngIf="!item.route" mat-button>{{item.name | translate}}
                        <span class="material-icons">
                            {{item.icon}}
                        </span>
                    </a>
                </ng-container>
            </span>
        </ng-container>
        <!-- <span class="spacer"></span> -->
        <div style="width: 40px;display: flex" (click)="op.toggle($event)" *ngIf="isLoggedIn && isVerified">
            <span matBadgePosition="above after" matBadgeColor="accent" [matBadge]="new_notifications"
                *ngIf="new_notifications > 0" class="material-icons m-auto cursor-pointer"
                [matTooltip]="'Notifications' | translate">
                notifications
            </span>
            <span matBadgePosition="above after" *ngIf="new_notifications == 0"
                class="material-icons m-auto cursor-pointer" [matTooltip]="'Notifications' | translate">
                notifications
            </span>
        </div>
        <span class="mt-2 ml-0 mr-2">
            <mat-menu #loginMenu="matMenu" [overlapTrigger]="false">
                <button class="p-color" mat-menu-item routerLink="/" style="display: flex;min-width: 250px;">
                    <span class="material-icons my-auto text-center" style="min-width: 33px;">
                        person
                    </span>
                    <span class="ml-2" style="text-transform: lowercase !important;"> {{loggedInName}} </span>
                </button>
                <button class="p-color" mat-menu-item style="display: flex;text-transform: none !important;">
                    <div class="custom-checkbox" (click)="$event.stopPropagation()">
                        <input id="status" (change)="switchLangByEvent($event)" type="checkbox" name="status">
                        <label for="status">
                            <div class="status-switch" data-unchecked="ro" data-checked="en">
                            </div>
                        </label>
                    </div>
                    <!-- <mat-slide-toggle color="primary"></mat-slide-toggle> -->
                    <span class="ml-2"> {{'DisplayLanguage' | translate}}
                    </span>
                </button>
                <button class="p-color" mat-menu-item (click)="auth.logout();" style="display: flex;">
                    <span class="material-icons my-auto text-center" style="min-width: 33px;">
                        logout
                    </span>
                    <span class="ml-2" style="text-transform: capitalize !important;">{{'Logout' | translate}} </span>
                </button>
            </mat-menu>
            <span class="material-icons m-auto cursor-pointer" [matMenuTriggerFor]="loginMenu"
                *ngIf="isLoggedIn && isVerified">
                account_circle
            </span>

            <!-- <a mat-button [matMenuTriggerFor]="loginMenu" *ngIf="isLoggedIn && isVerified" class="float-right href">
                {{loggedInName}}
            </a> -->
        </span>
        <!-- <img src="assets/images/ro.png" style="width: 24px;cursor: pointer;"
            [style.opacity]="translate.currentLang == 'ro' ? '1' : '0.4'" (click)="switchLang('ro')">
        <img src="assets/images/en.png" style="width: 24px; margin-left: 10px;cursor: pointer;"
            [style.opacity]="translate.currentLang == 'en' ? '1' : '0.4'" (click)="switchLang('en')"> -->
    </div>
</mat-toolbar>


<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="m-0 grid d-flex">
            <b class="primary-color">{{'Notifications' | translate}}</b>
            <b class="ml-auto mr-2 primary-color">
                <mat-slide-toggle (change)="filterNotifications()"
                    [(ngModel)]="notificationsService.showOnlyReadNotification" color="primary">
                    {{'OnlyUnread' | translate}}
                </mat-slide-toggle>
            </b>
            <a class="p-0 pl-2 underline cursor-pointer primary-color mark-as-read" *ngIf="notifications.length > 0"
                (click)="notificationsService.markAllNotificationsAsRead();">
                {{'MarkAsRead' | translate}}
            </a>
        </div>
        <hr class="m-0 my-1">
        <div class="m-0 grid d-flex" style="max-height: 500px;overflow: auto;">
            <ng-container *ngIf="notifications.length == 0">
                <p>
                    {{'NoNotifications' | translate}}
                </p>
            </ng-container>
            <ng-container *ngFor="let notification of notifications">
                <div class="notification-body" (click)="openNotification(notification)">
                    <div class="col-10 p-0 d-flex align-items-center cursor-pointer mt-2">
                        <b>{{notification.title}}</b>
                        <i class="material-icons" style="color: #a3a4a8;font-size: 20px;"
                            *ngIf="!notification.isRead">fiber_manual_record</i>
                    </div>
                    <div class="col-12 p-0 cursor-pointer" style="font-size: 13px;">
                        <i>{{notification.created | date:'medium'}}</i>
                    </div>
                    <div class="col-12 p-0 cursor-pointer" style="font-size: 13px;">
                        <p [innerHtml]="notification.message"></p>
                    </div>
                </div>
            </ng-container>
        </div>

    </ng-template>
</p-overlayPanel>

<p-dialog *ngIf="notificationsService.selecetedNotification" 
    [(visible)]="notificationsService.defaultViewForOpenedNotif"
    (onHide)="notificationsService.defaultViewForOpenedNotif = false" [modal]="true" [style]="{ width: '700px' }">
    <ng-template pTemplate="header">
        {{notificationsService.selecetedNotification.title}}
    </ng-template>
    <div class="col-12 p-0 cursor-pointer">
        <i>{{notificationsService.selecetedNotification.created | date:'medium'}}</i>
    </div>
    <div class="col-12 p-0 cursor-pointer">
        <p [innerHtml]="notificationsService.selecetedNotification.message"></p>
    </div>
    <ng-template pTemplate="footer">
        <button mat-raised-button color="primary" (click)="notificationsService.defaultViewForOpenedNotif = false">
            {{'Ok' | translate}}
        </button>
    </ng-template>
</p-dialog>