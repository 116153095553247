export interface User {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    code: string;
}

export interface LoginRequestModel {
    email: string;
    password: string;
}


export interface AuthSession {
    username: string;
    email: string;
    token: string;
    tokenType: string;
    expiration: string;
    verified: Date; //GDPR usage
    isVerified: boolean;
    verificationToken
    professor: Professor;
    administrativeStaff: AdministrativeStaff;
    administrativeStaffHasRoles: boolean;
    student: any;
    roles: any[];
}

export interface Professor {
    id: string;
    email: string;
    title: string;
    department: any;
    faculty: any;
    firstName: string;
    lastName: string;
    roles: ProfessorRoles;
    isValid: boolean;
    involvedInErasmusStudents: boolean;
    isCommissionMemberErasmusForStudents: boolean;
    isCommissionMemberErasmusForStaff: boolean;
    phone: string;
    citizenship: string;
    address: string;
    gender: string;
    authUserId: string;
}

export enum CoreRole {
    Professor,
    AdministrativeStaff,
    Student
}

export interface ProfessorRoles{
    id: string;
    professorId: string;
    managementRole: ManagementRole;
    professorRole: ProfessorRole;
    commissionRole: CommissionRole;
}

export enum ManagementRole{
    Rector,
    ProrectorRi,
    Vicedean
}

export enum ProfessorRole{
    Moderator,
    SuperAdministrator
}

export enum CommissionRole {
    Vicedean,
    BureauChief,
    AdministrativeStaff,
    MobilityCoordinator
}

export interface AdministrativeStaff {
    id: string;
    email: string;
    title: string;
    department: any;
    faculty: any;
    firstName: string;
    lastName: string;
    administrativeStaffRoles: AdministrativeStaffRole[];
    isValid: boolean;
    involvedInErasmusStudents: boolean;
    isCommissionMemberErasmusForStudents: boolean;
    isCommissionMemberErasmusForStaff: boolean;
    phone: string;
    citizenship: string;
    address: string;
    gender: string;
    authUserId: string;
}

export enum AdministrativeStaffRole{
    StudentStudiesUE,
    StudentStudiesNonUe,
    StudentInternshipUE,
    StudentInternshipNonUe,
    StaffTrainingUE,
    StaffTrainingNonUe,
    StaffTeachingUE,
    StaffTeachingNonUe
}

export interface ForgotPasswordModel {
    email: string;
}

export interface ConfirmationRequestModel {
    userId: string;
    token: string;
}
