import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { ExperienceModel } from '@upt/erasmus/types';
import { AuthService } from './auth.service';
import { environment } from '@upt/erasmus/shared';
const baseUrl = `${environment.apiUrl}/api/Experiences/`;

@Injectable({
    providedIn: 'root',
})
export class ExperiencesService {

    constructor(
        private http: HttpClient,
        private auth: AuthService
    ) {
    }

    public async getOwn(): Promise<ExperienceModel[]> {
        const url = `${baseUrl}GetOwn`;

        try {
            const res = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            return res;
        } catch {
            // TODO: treat exception
            return;
        }
    }

    public async update(model: ExperienceModel[]): Promise<ExperienceModel[]> {
        const url = `${baseUrl}SaveOwn`;
        return this.http.post<any>(url, model, await this.auth.getOptions(true)).pipe(map(res => {
            return res;
        })).toPromise();
    }
}
