import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'apps/erasmus/src/app/material.module';
import { PrimeNgModule } from 'apps/erasmus/src/app/primeng.module';
import { CustomValidatorsModule } from '@upt/erasmus/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InputErrorComponent } from './input-error.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PrimeNgModule,
        CustomValidatorsModule,
        TranslateModule
    ],
    declarations: [
        InputErrorComponent
    ],
    exports: [
        InputErrorComponent
    ]
})
export class InputErrorModule { }