import { EntityModel } from "./entity.model";
import { InternshipMobilityModel } from "./internship-mobility.model";
import { MessageModel } from "./message.model";
import { StudentProfileModel } from "./student-profile.model";
import { StudyMobilityModel } from "./study-mobility.model";


export interface NewApplicationModel extends EntityModel {
    mobilityType?: MobilityType;
}

export interface ApplicationModel extends NewApplicationModel {
    student?: StudentProfileModel;
    created?: Date;
    submittedDate?: Date | string;
    status?: ApplicationStatus;
    statusName?: string;
    studentName?: string;
    lastMessage?: string;
    mobility?: InternshipMobilityModel | StudyMobilityModel | { isEmpty?: boolean, id?: string } | any;
    justCreated?: boolean;
    declarationsAgreed?: boolean;
    messages?: MessageModel[];
    languageSkills?: any[],
    motivation: any,
    isShortTimeDurationApplication?: any,
}

export type MobilityType = 'Internship' | 'Studies';

export type ApplicationStatus = 'None' | 'WorkInProgress' | 'ReviewNeeded' | 'Processing' | 'Approved' | 'Rejected' | 'Canceled' | 'PendingRequest' | 'PendingRequest' | 'PendingForProof' | 'PendingRMPS' | 'Closed' | 'Finished';

export type MobilitiesFilter = {
  mobilityStatuses: string[];
  applicationStatuses: string[];
}
