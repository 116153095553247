
import { EntityModel } from './entity.model';
import { FacultyModel } from './faculty.model';
import { FieldOfStudyModel } from './field-of-study.model';
import { SpecializationModel } from './specialization.model';

export interface StudentProfileModel extends EntityModel {
    firstName?: string;
    lastName?: string;
    isEmpty?: boolean;
    faculty?: FacultyModel;
    fieldOfStudy?: FieldOfStudyModel;
    specialization?: SpecializationModel;
    yearOfStudy?: number;
    email?: string;
}
