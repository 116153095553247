<div *ngIf="!input?.valid && input?.errors && (input?.dirty || input?.touched)" class="error">
    <div *ngIf="input?.errors.required">
        {{'Error_IsRequired' | translate}}
    </div>
    <div *ngIf="input?.errors.minlength">
        {{'Error_MinLength' | translate:{value:input.errors.minlength?.requiredLength} }}
    </div>
    <div *ngIf="input?.errors.maxlength">
        {{'Error_MaxLength' | translate:{value:input.errors.maxlength?.requiredLength} }}
    </div>
    <div *ngIf="input?.errors.customMin">
        {{'Error_MinValue' | translate:{value:input.errors.requiredValue} }}
    </div>
    <div *ngIf="input?.errors.customMax">
        {{'Error_MaxValue' | translate:{value:input.errors.requiredValue} }}
    </div>
    <div *ngIf="input?.errors.email">
        Invalid Email Address.
    </div>
</div>