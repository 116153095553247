import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ReferencesHelperService } from './references-helper.service';
import { ApplicationModel, StudyMobilityModel, InternshipMobilityModel } from '@upt/erasmus/types';
import { CfgService } from './cfg.service';
import { environment } from '@upt/erasmus/shared';

const baseUrl = `${environment.apiUrl}/api/Applications/`;

@Injectable()
export class ApplicationService {


    constructor(
        private http: HttpClient,
        private cfg: CfgService,
        private auth: AuthService,
        private refService: ReferencesHelperService
        // private validationService: ValidationService,
        // private messages: DisplayMessageService,
    ) {
    }

    public async add(data: any): Promise<any> {
        const url = `${baseUrl}Create`;

        const result = await this.http.post<any>(url, data,
            await this.auth.getOptions(true)
        ).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }


    public async getOneApplicationStudent(id: string): Promise<ApplicationModel> {
        const url = `${baseUrl}GetOneStudent/${id}`;

        const result = await this.http.get<ApplicationModel>(url, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

    public async getNaces(): Promise<any[]> {
        const url = `${environment.apiUrl}/api/Naces/GetAll`;

        try {
            const result = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
            await this.populateAndPatch(result);
            return result;
        } catch (err) {
            //console.log(err)
            // TODO: treat exception
            return [];
        }
    }

    public async getOwn(): Promise<ApplicationModel[]> {
        const url = `${baseUrl}GetAll/${this.auth.appType}`;

        try {
            const result = await this.http.get<ApplicationModel[]>(url, await this.auth.getOptions(true)).toPromise();
            await this.populateAndPatch(result);
            return result;
        } catch (err) {
            //console.log(err)
            // TODO: treat exception
            return [];
        }
    }

    public async update(data: any): Promise<any> {
        const url = `${baseUrl}Update`;
        const result = await this.http.post<any>(url, data, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

    public async saveLanguageSkills(data: any, application: ApplicationModel): Promise<any> {
        const url = `${baseUrl}SaveLanguageSkills/${application.id}`;
        const result = await this.http.post(url, data, await this.auth.getOptions(true)).toPromise();
        return result;
    }

    public async saveMobility(data: any, application: any):
        Promise<InternshipMobilityModel | StudyMobilityModel> {
        let actionName = application.mobilityType === 'Internship' ? 'SaveInternshipMobility' : 'SaveStudyMobility';
        if (application.mobilityType == "Internship") {
            actionName = 'SaveInternshipMobility'
        } else if (application.mobilityType == 'Studies') {
            actionName = 'SaveStudyMobility'
        } else if (application.mobilityType == 'Training') {
            actionName = 'SaveTrainingMobility'
        } else if (application.mobilityType == 'Teaching') {
            actionName = 'SaveTeachingMobility'
        }
        const url = `${baseUrl}${actionName}/${application.id}`;
        const result = await this.http.post<InternshipMobilityModel | StudyMobilityModel>(url, data, await this.auth.getOptions(true)).toPromise();
        this.refService.populateReferences(result);
        return result;
    }

    public async changeStatus(applicationId: string, changeModel: { newStatus: string, oldStatus: string }): Promise<void> {
        const url = `${baseUrl}changeStatus/${applicationId}`;
        await this.http.post(url, changeModel, await this.auth.getOptions(true)).toPromise();
    }

    public async getPdfUrl(app: ApplicationModel, userId?: string): Promise<string> {
        const session = await this.auth.getSession();

        let params = new HttpParams()
            .set('appId', app.id)
            .set('token', session.token);

        if (userId) {
            params = params.set('userId', userId);
        }

        let url = `${environment.apiUrl}/api/pdf/PreviewErasmusApplicationPdf?${params.toString()}`;
        //console.log(url);
        await this.http.get(url, await this.auth.getOptions(true)).toPromise();

        return url;
    }

    public async getPdfTokenForApp(app: ApplicationModel): Promise<{ item1: string, item2: string }> {
        const url = `${baseUrl}GetPdfToken/${app.id}`;
        const response = await this.http.get<{ item1: string, item2: string }>(url,
            await this.auth.getOptions(true)).toPromise();
        return response;
    }

    public async previewPdf(app: ApplicationModel, silent?: boolean, asModerator?: boolean): Promise<boolean> {
        const validResult = { valid: true }//await this.validationService.isValid(app.id);
        if (!validResult.valid) {
            if (!silent) {
                // this.messages.showErrorMessage('This application isn\'t valid. '
                //     + (asModerator ? 'You can\'t view an invalid application' : 'Please validate it before previewing.'), true);
            }
            return false;
        }
        // const tokenObj = await this.getPdfTokenForApp(app);
        if (window && window.open) {
            window.open(await this.getPdfUrl(app), '_blank');
            return true;
        }
        return false;
    }

    protected async populateAndPatch(data: ApplicationModel[]): Promise<void> {
        for (const app of data) {
            if (typeof app.submittedDate === 'string' && app.submittedDate) {
                if (app.submittedDate === '0001-01-01T00:00:00') {
                    app.submittedDate = undefined;
                } else {
                    app.submittedDate = new Date(app.submittedDate);
                }
            }
            const statusObj = this.cfg.appStatuses.find(s => s.Value === app.status);
            app.statusName = statusObj ? statusObj.Name : 'Invalid status';
            if (app.messages && app.messages.length > 0) {
                app.lastMessage = app.messages[0].body !== undefined ? app.messages[0].body : '--';
                for (const message of app.messages) {
                    const oldStatus = this.cfg.appStatuses.find(s => s.Value === message.oldStatus);
                    const newStatus = this.cfg.appStatuses.find(s => s.Value === message.newStatus);
                    message.oldStatus = oldStatus ? oldStatus.Name : 'Invalid status';
                    message.newStatus = newStatus ? newStatus.Name : 'Invalid status';
                }
            }

        }
        this.refService.populateReferences(data);
    }

    public async getOneApplicationStaff(id: string): Promise<any> {
        const url = `${baseUrl}GetOneStaff/${id}`;
        const result = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

    public async getApplicationById(id: string): Promise<any> {
        const url = `${baseUrl}GetById/${id}`;
        const result = await this.http.get<any>(url, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

    public async addApplicationStaff(data: any): Promise<any> {
        const url = `${baseUrl}CreateStaff`;
        const result = await this.http.post<any>(url, data, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

    public async updateApplicationStaff(data: any): Promise<any> {
        const url = `${baseUrl}UpdateStaff`;
        const result = await this.http.post<any>(url, data, await this.auth.getOptions(true)).toPromise();
        await this.populateAndPatch([result]);
        return result;
    }

}
