import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CfgService {

  public counties = [
    { Name: "Alba", Value: "AB" },
    { Name: "Arad", Value: "AR" },
    { Name: "Argeș", Value: "AG" },
    { Name: "Bacău", Value: "BC" },
    { Name: "Bihor", Value: "BH" },
    { Name: "Bistrița-Năsăud", Value: "BN" },
    { Name: "Botoșani", Value: "BT" },
    { Name: "Brașov", Value: "BV" },
    { Name: "Brăila", Value: "BR" },
    { Name: "Buzău", Value: "BZ" },
    { Name: "Caraș-Severin", Value: "CS" },
    { Name: "Călărași", Value: "CL" },
    { Name: "Cluj", Value: "CJ" },
    { Name: "Constanța", Value: "CT" },
    { Name: "Covasna", Value: "CV" },
    { Name: "Dâmbovița", Value: "DB" },
    { Name: "Dolj", Value: "DJ" },
    { Name: "Galați", Value: "GL" },
    { Name: "Giurgiu", Value: "GR" },
    { Name: "Gorj", Value: "GJ" },
    { Name: "Harghita", Value: "HR" },
    { Name: "Hunedoara", Value: "HD" },
    { Name: "Ialomița", Value: "IL" },
    { Name: "Iași", Value: "IS" },
    { Name: "Ilfov", Value: "IF" },
    { Name: "Maramureș", Value: "MM" },
    { Name: "Mehedinți", Value: "MH" },
    { Name: "Mureș", Value: "MS" },
    { Name: "Neamț", Value: "NT" },
    { Name: "Olt", Value: "OT" },
    { Name: "Prahova", Value: "PH" },
    { Name: "Satu Mare", Value: "SM" },
    { Name: "Sălaj", Value: "SJ" },
    { Name: "Sibiu", Value: "SB" },
    { Name: "Suceava", Value: "SV" },
    { Name: "Teleorman", Value: "TR" },
    { Name: "Timiș", Value: "TM" },
    { Name: "Tulcea", Value: "TL" },
    { Name: "Vaslui", Value: "VS" },
    { Name: "Vâlcea", Value: "VL" },
    { Name: "Vrancea", Value: "VN" },
    { Name: "București", Value: "B" }
  ]
  public countries = [];
  public currencies = [
    { Name: "RON", Value: "RON" },
    { Name: "EUR", Value: "EUR" }
  ]
  public periodType = [
    { Name: "Săptămâni", Value: "Săptămâni" },
    { Name: "Luni", Value: "Luni" },
  ]
  private departments = [
    { Value: "ARH", Name: "Arhitectură (ARH)", GroupName: "Arhitectură şi Urbanism" },
    { Value: "AIA", Name: "Automatică şi Informatică Aplicată (AIA)", GroupName: "Automatică şi Calculatoare" },
    { Value: "CTI", Name: "Calculatoare și Tehnologia Informației (CTI)", GroupName: "Automatică şi Calculatoare" },
    { Value: "CEPTM", Name: "Centrul de Educație Permanentă Timișoara (CEP TM)", GroupName: "Altele" },
    { Value: "CEPHD", Name: "Centrul de Educație Permanentă Hunedoara (CEP HD)", GroupName: "Altele" },
    { Value: "CAICON", Name: "Chimie Aplicată și Ingineria Compușilor Organici și Naturali (CAICON)", GroupName: "Chimie Industrială şi Ingineria Mediului" },
    { Value: "CAICAM", Name: "Chimie Aplicată și Ingineria Compușilor Anorganici și a Mediului (CAICAM)", GroupName: "Chimie Industrială şi Ingineria Mediului" },
    { Value: "CMMC", Name: "Construcţii Metalice şi Mecanica Construcţiilor (CMMC)", GroupName: "Construcții" },
    { Value: "CCTFC", Name: "Căi de Comunicaţie Terestre, Fundaţii şi Cadastru (CCTFC)", GroupName: "Construcții" },
    { Value: "CCI", Name: "Construcţii Civile şi Instalaţii (CCI)", GroupName: "Construcții" },
    { Value: "HIDRO", Name: "Hidrotehnică (HIDRO)", GroupName: "Construcții" },
    { Value: "IE", Name: "Inginerie Electrică (IE)", GroupName: "Electrotehnică și Electroenergetică" },
    { Value: "DEE", Name: "Electroenergetică (DEE)", GroupName: "Electrotehnică și Electroenergetică" },
    { Value: "BFI", Name: "Bazele Fizice ale Ingineriei (BFI)", GroupName: "Electrotehnică și Electroenergetică" },
    { Value: "DCOM", Name: "Comunicaţii (DCOM)", GroupName: "Electronică, Telecomunicații și Tehnologii Informaționale" },
    { Value: "EA", Name: "Electronică Aplicată (EA)", GroupName: "Electronică, Telecomunicații și Tehnologii Informaționale" },
    { Value: "MEO", Name: "Măsurări şi Electronică Optică (MEO)", GroupName: "Electronică, Telecomunicații și Tehnologii Informaționale" },
    { Value: "DIM", Name: "Inginerie şi Management (DIM)", GroupName: "Inginerie Hunedoara" },
    { Value: "DIEII", Name: "Inginerie Electrică şi Informatică Industrială (DIEII)", GroupName: "Inginerie Hunedoara" },
    { Value: "MPT", Name: "Management (MPT)", GroupName: "Management in Producție și Transporturi" },
    { Value: "MCTR", Name: "Mecatronică şi Robotică (MCTR)", GroupName: "Mecanică" },
    { Value: "MRM", Name: "Mecanică şi Rezistenţa Materialelor (MRM)", GroupName: "Mecanică" },
    { Value: "MMUT", Name: "Maşini Mecanice, Utilaje şi Transporturi (MMUT)", GroupName: "Mecanică" },
    { Value: "IMF", Name: "Ingineria Materialelor şi Fabricaţiei (IMF)", GroupName: "Mecanică" },
    { Value: "DCLS", Name: "Comunicare şi Limbi Străine (DCLS)", GroupName: "Știinte ale Comunicării" },
    { Value: "DPPD", Name: "Departamentul pentru Pregătirea Personalului Didactic (DPPD)", GroupName: "Știinte ale Comunicării" },
    { Value: "MAT", Name: "Matematică (MAT)", GroupName: "Altele" },
    { Value: "EFS", Name: "Educaţie Fizică şi Sport (EFS)", GroupName: "Altele" }
  ];
  public departmentsGrouped = [];

  public researchCenters = [
    { Name: "-" },
    { Name: "Centrul de Cercetări în Inginerie Integrată" },
    { Name: "Centrul de Cercetări pentru Planificare Urbană" },
    { Name: "Centrul de Cercetări în Metode Avansate de Studiu al fenomenelor Fizice" },
    { Name: "Centrul de Cercetări în Ingineria Sistemelor cu Fluide Complexe" },
    { Name: "Centrul de Cercetări pentru Mașini și Echipamente Termice, Transporturi și Combaterea Poluării" },
    { Name: "Centrul de Cercetări în Hidrotehnică și Protecția Mediului" },
    { Name: "Centrul de Cercetări Multimedia" },
    { Name: "Centrul de Cercetări în Ingineria Sistemelor Automate" },
    { Name: "Centrul de Cercetări în Chimia și Ingineria Compușilor Organici, Macromoleculari și Naturali" },
    { Name: "Centrul de Cercetări pentru Mecanica Materialelor și Siguranța Structurilor" },
    { Name: "Centrul de Cercetări în Mecatronică și Robotică" },
    { Name: "Centrul de Cercetări în Infrastructuri pentru Construcții și Transporturi" },
    { Name: "Centrul de Cercetări în Controlul Inteligent al Conversiei și Stocării Energiei" },
    { Name: "Centrul de Cercetări pentru Materiale Anorganice și Energii Alternative" },
    { Name: "Centrul de Cercetări în Instalații pentru Construcții" },
    { Name: "Centrul de Cercetări în Calculatoare și Tehnologia Informației" },
    { Name: "Centrul de Cercetări în Sisteme Electronice Inteligente" },
    { Name: "Centrul de Cercetări în Prelucrarea Inteligentă a Semnalelor" },
    { Name: "Centrul de Cercetări pentru Analiza și Optimizarea Regimurilor Sistemelor Electroenergetice" },
    { Name: "Centrul de Cercetări în Știința și Ingineria Mediului" },
    { Name: "Centrul de Cercetări pentru Reabilitarea Construcțiilor" },
    { Name: "Centrul de Cercetări în Inginerie Medicală" },
    { Name: "Centrul de Cercetări pentru Procesarea și Caracterizarea Materialelor Avansate" },
    { Name: "Centrul de Cercetări în Inginerie și Management" },
    { Name: "ICER - Institutul de Cercetări pentru Energii Regenerabile" },
    { Name: "Laboratorul de Acustică și Vibrații" },
    { Name: "Centrul de Educație Permanentă Timișoara (CEP TM)" },
    { Name: "Centrul de Educație Permanentă Hunedoara (CEP HD)" }
  ]

  public contractTypes = [
    { Name: "Normal", Value: "Normal" },
    { Name: "Cadru", Value: "Cadru" },
    { Name: "Subsecvent", Value: "Subsecvent" },
    { Name: "International", Value: "International" },
  ]

  public idCardTypes = [
    { Value: "IdCard", Name: "C.I. / National ID card" },
    { Value: "Passport", Name: "Pașaport / Passport" },
    { Value: "ResidencePermit", Name: "Permis de ședere / Residence permit" }
  ]

  public languages = [
    { Value: "", Name: "None" },
    { Value: "Bulgarian", Name: "Bulgarian / Bulgară" },
    { Value: "Catalan", Name: "Catalan / Catalană" },
    { Value: "Czech", Name: "Czech / Cehă" },
    { Value: "Croatian", Name: "Croatian / Croată" },
    { Value: "Danish", Name: "Danish / Daneză" },
    { Value: "English", Name: "English / Engleză" },
    { Value: "Finnish", Name: "Finnish / Finlandeză" },
    { Value: "Flemish", Name: "Flemish / Flamandă" },
    { Value: "French", Name: "French / Franceză" },
    { Value: "Georgian", Name: "Georgian / Georgiană" },
    { Value: "German", Name: "German / Germană" },
    { Value: "Greek", Name: "Greek / Greacă" },
    { Value: "Icelandic", Name: "Icelandic / Islandeză" },
    { Value: "Italian", Name: "Italian / Italiană" },
    { Value: "Japanese", Name: "Japanese / Japoneza" },
    { Value: "Latvian", Name: "Latvian / Letonă" },
    { Value: "Lithuanian", Name: "Lithuanian / Lituaniană" },
    { Value: "Macedonian", Name: "Macedonian / Macedoneană" },
    { Value: "Hungarian", Name: "Hungarian / Maghiară" },
    { Value: "Norwegian", Name: "Norwegian / Norvegiană" },
    { Value: "Dutch", Name: "Dutch / Olandeză" },
    { Value: "Polish", Name: "Polish / Poloneză" },
    { Value: "Portuguese", Name: "Portuguese / Portugheză" },
    { Value: "Romanian", Name: "Romanian / Română" },
    { Value: "Russian", Name: "Russian / Rusă" },
    { Value: "Serbian", Name: "Serbian / Sârbă" },
    { Value: "Slovak", Name: "Slovak / Slovacă" },
    { Value: "Slovenian", Name: "Slovenian / Slovenă" },
    { Value: "Spanish", Name: "Spanish / Spaniolă" },
    { Value: "Turkish", Name: "Turkish / Turcă" }
  ];
  public languageLevels = [
    { Value: "A1", Name: "A1" },
    { Value: "A2", Name: "A2" },
    { Value: "B1", Name: "B1" },
    { Value: "B2", Name: "B2" },
    { Value: "C1", Name: "C1" },
    { Value: "C2", Name: "C2" }
  ]
  public levelOfStudies = [
    { Value: "Bachelor", Name: "Licență" },
    { Value: "Master", Name: "Master" },
    { Value: "PhD", Name: "Doctorat" }
  ];
  public selectAppStatuses = [
    // { Value: "WorkInProgress", Name: "Work in progress" },
    { Value: 'ReviewNeeded', Name: "Review needed" },
    // { Value: "Submitted", Name: "Submitted" },
    // { Value: "Processing", Name: "Processing" },
    { Value: 'Approved', Name: "Approved for evaluation" },
    { Value: 'Rejected', Name: 'Rejected' },
    // { Value: "Canceled", Name: "Canceled" },
    // { Value: 'ReadyForEvaluation', Name: 'ReadyForEvaluation' },
    // { Value: "InReserve", Name: "InReserve" }
  ];
  public appStatuses = [
    { Value: "WorkInProgress", Name: "Work in progress" },
    { Value: 'ReviewNeeded', Name: "Review needed" },
    { Value: "Submitted", Name: "Submitted" },
    // { Value: "Processing", Name: "Processing" },
    { Value: 'Approved', Name: 'Approved' },
    { Value: 'Rejected', Name: 'Rejected' },
    { Value: "Canceled", Name: "Canceled" },
    { Value: 'ReadyForEvaluation', Name: 'ReadyForEvaluation' },
    { Value: "InReserve", Name: "InReserve" },
    { Value: "PendingRequest", Name: "PendingRequest" },
    { Value: "PendingForProof", Name: "PendingForProof" },
    { Value: "PendingRMPS", Name: "PendingRMPS" },
    { Value: 'Finished', Name: 'Finished' },
    { Value: "Closed", Name: "Closed" }
  ];
  public semestersAppErasmus = [
    { Value: "Both", Name: "Ambele / Both" },
    { Value: "S1", Name: "S1 - toamnă / fall" },
    { Value: "S2", Name: "S2 - primăvară / spring" }
  ]
  public internshipPurposes = [
    { Value: "Summer", Name: "Practică de vară / Summer internship" },
    { Value: "Disertation", Name: "Elaborarea lucrare de diplomă, disertație / Preparation of the diploma or dissertation project" },
    { Value: "PhD", Name: "Elaborarea lucrare de doctorat / Preparation of the PhD thesis" },
    { Value: "Abs", Name: "Practică pentru tineri absolvenți / Internship for recent graduates (ABS)" }
  ]
  public commissionMemberRolesForStudies = [
    { Value: "BureauChief", Required: true },
    { Value: "AdministrativeStaff", Required: true },
    { Value: "Vicedean", Required: true },
    { Value: "MobilityCoordinator", Required: false }
    // { Value: "Applicant", Required: false }
  ]
  public commissionMemberRolesForInternship = [
    { Value: "BureauChief", Required: true },
    { Value: "AdministrativeStaff", Required: true },
    { Value: "Vicedean", Required: true }
  ]
  public commissionMemberRolesForTraining = [
    { Value: "BureauChief", Required: true },
    { Value: "AdministrativeStaff", Required: true },
    { Value: "Vicedean", Required: true }
  ]
  public commissionMemberRolesForTeaching = [
    { Value: "BureauChief", Required: true },
    { Value: "AdministrativeStaff", Required: true },
    { Value: "Vicedean", Required: true }
  ]

  public coreRoles = [
    "Professor",
    "AdministrativeStaff",
    "Student"
  ]

  public professorRoles = [
    '-',
    "Moderator",
    "SuperAdministrator"
  ]
  public managementRoles = [
    '-',
    "Rector",
    "ProrectorRi",
    "Vicedean"
  ]
  public commissionRoles = [
    "Vicedean",
    "BureauChief",
    "AdministrativeStaff",
    "MobilityCoordinator"
  ]
  public administrativeStaffRoles = [
    "StudentStudiesUE",
    "StudentStudiesNonUe",
    "StudentInternshipUE",
    "StudentInternshipNonUe",
    "StaffTrainingUE",
    "StaffTrainingNonUe",
    "StaffTeachingUE",
    "StaffTeachingNonUe"
  ]

  public mobilityDocumentStatuses = [
    { Value: "New", Name: "New" },
    { Value: 'ReviewNeeded', Name: "Review needed" },
    // { Value: "Uploaded", Name: "Uploaded" },
    // { Value: "Viewed", Name: "Viewed" },
    { Value: 'Approved', Name: 'Approved' },
    { Value: 'Rejected', Name: 'Rejected' },
    // { Value: "ExpectingSignatures", Name: "ExpectingSignatures" },
    // { Value: "SignedByApplicant", Name: "SignedByApplicant" },
    { Value: "ExpectingApproval", Name: "ExpectingApproval" },
    // { Value: "WaitingForApplicationApproval", Name: "WaitingForApplicationApproval" }
  ];
  public erasmusContractTypes = [
    { Value: "FinancialContract" },
    { Value: "AddendumForAccountChange" },
    { Value: "AddendumForPeriodExtension" },
    { Value: "AddendumForPeriodReduction" },
    { Value: "AddendumForMajorPower" },
    { Value: "AddendumForSocialGrant" },
    { Value: "AddendumForDisabilities" },
    { Value: "AddendumForExceptionalCosts" }
  ]
  public erasmusCFKATypes = [
    { Value: "CFUEKA103Study" },
    { Value: "CFUEKA103Internship" },
    { Value: "CFNONUEKA107Study" },
    { Value: "CFNONUEKA107Internship" },
    { Value: "CFUEKA103Staff" },
    { Value: "CFNONUEKA107Staff" },
    { Value: "ADUEKA103Student" },
    { Value: "ADNONUEKA107Student" },
    { Value: "ADUEKA103Staff" },
    { Value: "ADNONUEKA107Staff" }
  ]
  public MobilityWaiverApprovalTypes = [
    { Value: "ApprovedFinancialContract", Name: "Contract Financiar Existent" },
    { Value: "FirstPaymentDone", Name: "Contract Financiar Existent & Prima Tranșă Virată" },
  ]
  public isMobile = false;
  constructor() {
    this.initConfig();
  }

  public initConfig() {
    this.isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) || document.body.clientWidth < 675;
    let distinctGroups = []
    for (let i = 0; i < this.departments.length; i++) {
      const department = this.departments[i];
      !distinctGroups.includes(department.GroupName) ? distinctGroups.push(department.GroupName) : {}
    }

    for (let i = 0; i < distinctGroups.length; i++) {
      const group = distinctGroups[i];
      let items = this.departments.filter(d => d.GroupName == group);
      this.departmentsGrouped.push({
        Value: group,
        Name: group,
        items
      })
    }

  }

  public get hiddenDetails() {
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof (<any>document).msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof (<any>document).webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    return {
      hidden,
      visibilityChange
    }
  }
}
