import { Component, Input, OnInit } from '@angular/core';
import { AuthService, CfgService, DashboardService, HttpService, MobilitiesService } from '@upt/erasmus/shared';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'upt-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(
    public cfg: CfgService,
    private httpService: HttpService,
    private messageService: MessageService,
    private auth: AuthService,
    private mobilitiesService: MobilitiesService
  ) { }

  ngOnInit(): void {
  }

}
