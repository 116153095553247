import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { StorageModule } from '@ngx-pwa/local-storage';

import { MaterialModule } from './material.module';
import { PrimeNgModule } from './primeng.module';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/core/landing-page/landing-page.component';
import { HeaderMenuComponent } from './components/core/header-menu/header-menu.component';
import { NotFoundPageComponent } from './components/core/not-found-page/not-found-page.component';
import { LoginComponent } from './components/core/login/login.component';
import { SubmitButtonComponent } from './components/core/submit-button/submit-button.component';
import { ApplicationService, AttachmentsService, CustomValidatorsModule, DashboardService, ExperiencesService, StudentProfileService, StudyAgreementsService, HttpService } from '@upt/erasmus/shared';

import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MobilityUsersModule } from './components/moderator/internal-data/mobility-users/mobility-users.module';
import { UsersComponent } from './components/moderator/users/users.component';
import { InputErrorModule } from './components/core/input-error/input-error-form.module';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    LandingPageComponent,
    LoginComponent,
    SubmitButtonComponent,
    NotFoundPageComponent,
    UsersComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimeNgModule,
    CustomValidatorsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxFileDropModule,
    MobilityUsersModule,
    InputErrorModule,
    StorageModule.forRoot({ IDBNoWrap: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StudentProfileService,
    ApplicationService,
    ExperiencesService,
    StudyAgreementsService,
    AttachmentsService,
    DashboardService,
    HttpService,
    MessageService,
    ConfirmationService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}