import { Component, OnInit } from '@angular/core';
import { AuthService } from '@upt/erasmus/shared';

@Component({
  selector: 'upt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // andrei.voicu@student.upt.ro
  // anisia.rosu@student.upt.ro
  // radu.bals@student.upt.ro
  public form = {
    email: '',
    password: ''
  }
  public state: 'loading-form' | 'submitting' | 'ready' = 'ready';
  public messages = []
  constructor(
    private auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  public get loadingForm() {
    return this.state === 'loading-form';
  }

  public get submitting() {
    return this.state === 'submitting';
  }

  public async submit(): Promise<void> {
    // alert(true)
    if (this.state !== 'ready') {
      return;
    }
    // if (!this.form.valid) {
    //   this.form.markAsTouched();
    //   return;
    // }

    this.messages = [];
    this.state = 'submitting';
    try {
      //   await this.dialogConfig.submitAction(this.form.value);
      await this.auth.login(this.form);
      //   if (typeof this.dialogConfig.successAction === 'function') {
      //     this.dialogConfig.successAction();
      // }
    } catch (error) {
      // console.log(e.error);
      // if (e.error.data) {
      //   this.messages = [{ severity: 'error', summary: '', detail: e.error.data, escape: false }];
      // }
      let defError = 'Unexpected error occurred.';
      let err = error.error ? error.error.message ? error.error.message : defError : defError;;
      this.messages = [{ severity: 'error', summary: 'Error', detail: err, life: 5000 }];
      //   this.snacks.showError(e, true);
    }
    this.state = 'ready';
  }

  public get display() {
    return this.auth.displayLoginModal;
  }

  public set display(v: boolean) {
    this.auth.displayLoginModal = v;
  }

}
