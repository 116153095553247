import { ApplicationModel } from "./application.model";
import { EntityModel } from "./entity.model";

export interface AttachmentModel extends EntityModel {
    type?: string;
    description?: string;
    attachment?: any;
    file?: any;
    application?: ApplicationModel;
    template?: any;
}
