import { CoreRole, Professor } from './../../../../types/src/lib/erasmus/user.model';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/internal/operators';
import {
    AuthSession,
    LoginRequestModel
} from '@upt/erasmus/types';

import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { environment } from '@upt/erasmus/shared';

const baseUrl = `${environment.apiUrl}/api/Auth/`;

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private static readonly tokenStorageKey: string = 'token';
    private static readonly sessionStorageKey: string = 'session';
    public static readonly appTypeStorageKey: string = 'appType';

    private readonly baseUrl: string;

    public _token: string;
    private _session: AuthSession;
    private _appType: string;
    public appType: string;

    public _authState: EventEmitter<boolean> = new EventEmitter();

    public displayLoginModal = false;
    constructor(private http: HttpClient,
        private storage: LocalStorage,
        private router: Router) {
        this.loadSession();
    }

    public get authStateChanged(): EventEmitter<boolean> {
        return this._authState;
    }

    public get authState(): boolean {
        return this.localAuthState;
    }

    public get authStateAsync(): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.localAuthState) {
                resolve(true);
            } else {
                await this.loadSession();
                resolve(this.localAuthState);
            }
        });
    }

    public async hasRole(role: string): Promise<boolean> {
        const session = await this.getSession();

        if (!session.professor && !session.administrativeStaff && !session.student) {
            return false;
        }

        if (session.professor){
            if (role == CoreRole[CoreRole.Professor]
                || role == session.professor?.roles?.managementRole?.toString() || role == session.professor?.roles?.professorRole?.toString()){
                    return true;
                }
        }

        else if (session.administrativeStaff){
            if (role == CoreRole[CoreRole.AdministrativeStaff]
                || session.administrativeStaff?.administrativeStaffRoles?.some(ar => ar.toString() == role)){
                    return true;
                }
        }

        else if(session.student){
            if(role == CoreRole[CoreRole.Student]){
                return true;
            }
        }

        else{
            return false;
        }
    }

    public get localAuthState(): boolean {
        return !!this._token;
    }

    public async getOptions(needsAuth?: boolean): Promise<{ headers?: HttpHeaders }> {
        return { headers: await this.getHeaders(needsAuth) };
    }

    public async getHeaders(needsAuth?: boolean): Promise<HttpHeaders> {
        if (!needsAuth) {
            return new HttpHeaders();
        }
        const session = await this.getSession();

        if (!session) {
            return new HttpHeaders();
        }

        return new HttpHeaders().append('Authorization', `Bearer ${session.token}`);
    }


    public async verifyEmail(token: string) {
        return await this.http.post(`${baseUrl}verify-email`, { token }, await this.getOptions(true)).toPromise();
    }

    public async refreshSession() {
        return await this.http.post(`${baseUrl}refresh-session`, null, await this.getOptions(true)).toPromise();
    }

    public async login(requestModel: LoginRequestModel): Promise<any> {
        const url = `${baseUrl}Login`;
        return this.http.post<AuthSession>(url, requestModel)
            .pipe(tap(async res => {
                const authSession = res;
                // console.log(authSession);
                await this.saveSession(authSession);
            }))
            .pipe(map(() => {
                return true;
            })).toPromise();
    }

    public async logout(): Promise<void> {
        try {
            await this.saveSession();
            this._appType = null;
            this._session = null;
            this._token = null;
            await this.router.navigate(['/']);
        } catch (error) {

        }
    }

    public async getToken(): Promise<string> {
        if (!this._token) {
            await this.loadSession();
        }
        return this._token;
    }

    public async getSession(): Promise<AuthSession> {
        if (!this._session) {
            this._session = <AuthSession>await this.storage.getItem(AuthService.sessionStorageKey).toPromise();
        }
        return this._session;
    }

    public async getAppType(): Promise<string> {
        if (!this._appType) {
            this._appType = <string>await this.storage.getItem(AuthService.appTypeStorageKey).toPromise();
        }
        return this._appType;
    }

    public async saveSession(authSession?: AuthSession): Promise<void> {
        //console.log(authSession);
        if (authSession) {
            // view by role
            authSession['roles'] = [];
            authSession['hasRightToVote'] = true;
            if (authSession.professor) {
                //necessary?
                // if(authSession.professor?.roles?.commissionRole){
                //     authSession.roles.push(authSession.professor.roles.commissionRole);
                // }
                if(authSession.professor?.roles?.managementRole){
                    authSession.roles.push(authSession.professor.roles.managementRole);
                }
                if(authSession.professor?.roles?.professorRole){
                    authSession.roles.push(authSession.professor.roles.professorRole);
                }

                //to check
                let p = authSession.professor;
                if (p.isCommissionMemberErasmusForStudents || p.isCommissionMemberErasmusForStaff) {
                    authSession['roles'].push("CommissionMember");
                }
            }
            if (authSession.roles.includes('SuperAdministrator') || authSession.roles.includes('Moderator')) {
                authSession['roles'].push("InternalModerator");
            }
            if (authSession.administrativeStaff != null) {
                authSession['hasRightToVote'] = false;
                authSession.administrativeStaff.administrativeStaffRoles.forEach(r => authSession.roles.push(r));
                authSession.administrativeStaffHasRoles = authSession.administrativeStaff.administrativeStaffRoles?.length != 0;

                let as = authSession.administrativeStaff;
                if (as.isCommissionMemberErasmusForStudents || as.isCommissionMemberErasmusForStaff) {
                    authSession['roles'].push("CommissionMember");
                }
            }

           // console.log(authSession.roles)
            await this.storage.setItem(AuthService.tokenStorageKey, authSession.token).toPromise();
            await this.storage.setItem(AuthService.sessionStorageKey, authSession).toPromise();
            if (authSession.student != null) {
                await this.storage.setItem(AuthService.appTypeStorageKey, "ErasmusForStudents").toPromise();
            }
        } else {
            await this.storage.removeItem(AuthService.tokenStorageKey).toPromise();
            await this.storage.removeItem(AuthService.sessionStorageKey).toPromise();
            await this.storage.removeItem(AuthService.appTypeStorageKey).toPromise();
            await this.storage.clear().toPromise();
        }
        await this.loadSession();
        this.displayLoginModal = false;
    }

    private async loadSession(): Promise<void> {
        const initialStatus = !!this._token;
        this._appType = <string>await this.storage.getItem(AuthService.appTypeStorageKey).toPromise();
        this.appType = this._appType;
        this._token = <string>await this.storage.getItem(AuthService.tokenStorageKey).toPromise();
        if (this._token) {
            this._session = <AuthSession>await this.storage.getItem(AuthService.sessionStorageKey).toPromise();
        }
        const differentStatus = initialStatus !== !!this._token;
        if (differentStatus) {
            this._authState.emit(!!this._token);
        }
    }

}
