import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AuthService, HttpService, NotificationsService, LanguageService, CfgService } from '@upt/erasmus/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  public loggedInName: string;
  public isLoggedIn: boolean;
  public isVerified: boolean;
  public roles = [];
  public loggedUser: any;
  public appType: any;
  public menuItems: any = []
  public menuDisabled: boolean = false; // TODO
  @ViewChild('op') op: any

  public availableRoutes = {
    "Profile": "/profile/edit",
    "LanguageSkills": "/language-skills",
    "MobilityApplications": "/applications/list",
    "Dashboard": "/moderator",
    "ComisssionsAndEvaluations": "/moderator/active-mobilities",
    "UsersManagement": "/moderator/users",
    "Reports": "moderator/reports"
  }
  public idGetNotificationList = null;
  constructor(
    // public authDialogs: AuthDialogsService,
    public auth: AuthService,
    public notificationsService: NotificationsService,
    public httpService: HttpService,
    public cfg: CfgService,
    public translate: TranslateService,
    public languageService: LanguageService
  ) {
    this.translate.addLangs(['en', 'ro']);
    this.translate.setDefaultLang('ro');
    this.translate.use("ro");
    //console.log(this.translate.currentLang)
    //console.log(this.translate)
    //console.log(this.translate.store.translations[`${this.translate.currentLang}`])
    this.authStatusChanged(this.auth.authState).then(() => { });
    auth.authStateChanged.subscribe(async (status) => {
      this.menuItems = [];
      await this.authStatusChanged(status);
      try {
        await this.notificationsService.getOwnNotificationsFiltered()
      } catch (error) {
        //console.log(error)
        if (error.status == 401) {
          this.auth.logout();
        }
      }
    });
  }

  public ngOnInit() {
    this.setMenuItems();
    document.onvisibilitychange = () => this.filterNotifications()
    this.idGetNotificationList = setInterval(async () => {
      if (!document[this.cfg.hiddenDetails.hidden]) {
        this.filterNotifications();
      }
    }, 5 * 60000)
  }

  ngOnDestroy(): void {
    clearInterval(this.idGetNotificationList)
  }

  public async authStatusChanged(status) {
    if (status === false) {
      this.isLoggedIn = false;
      return;
    }
    this.isLoggedIn = true;
    this.loggedUser = await this.auth.getSession();
    this.loggedInName = this.loggedUser.email;
    this.isVerified = this.loggedUser.student != null ? this.loggedUser.isVerified : true;
    //console.log(this.loggedUser)
    this.roles = this.loggedUser.roles;
    console.warn(this.roles, this.isLoggedIn)
    this.appType = await this.auth.getAppType();
    this.setMenuItems();
    //console.log(this.menuItems);
  }

  private async setMenuItems() {
    let routes = []

    //ERASMUS FOR STAFF
    if (this.appType == "ErasmusForStaff"){
      this.availableRoutes['Profile'] = "/staff-profile/edit";

      //PROFESSOR
      if(this.loggedUser.professor){
        routes = ["Profile", "MobilityApplications"];
        if (this.loggedUser.professor.isCommissionMemberErasmusForStaff) {
          routes = ["Dashboard", ...routes];
          routes.push("ComisssionsAndEvaluations");
        }

        if (this.roles.includes("SuperAdministrator") || this.roles.includes("Moderator") || this.roles.includes('Rector') || this.roles.includes('ProrectorRi')) {
          routes = ["Dashboard", ...routes];
        }
      }

      //ADMINISTRATIVE STAFF
      else if (this.loggedUser.administrativeStaff){
        routes = ["Profile", "MobilityApplications"];
        if (this.roles.length != 0) {
          routes = ["Dashboard", ...routes];
        }
      }
    }

    // ERASMUS FOR STUDENTS
    else if (this.appType == "ErasmusForStudents"){
      this.availableRoutes['Profile'] = "/profile/edit";
      
      //STUDENT
      if (this.loggedUser.student) {
        routes = ["Profile", "LanguageSkills", "MobilityApplications"];
        this.menuItems = routes.map((r: any) => r = { name: r, route: this.availableRoutes[r] });
        return;
      }

      //PROFESSOR
      else if (this.loggedUser.professor){
        if (this.loggedUser.professor.isCommissionMemberErasmusForStudents) {
          routes.push("ComisssionsAndEvaluations");
        }

        if (this.roles.includes("SuperAdministrator") || this.roles.includes("Moderator") || this.roles.includes('Rector') || this.roles.includes('ProrectorRi')) {
          routes = ["Dashboard", ...routes];
        }
      }

      //ADMINISTRATIVE STAFF
      else if (this.loggedUser.administrativeStaff){
        //todo
      }
    }

    // avoid duplicates
    let unqiueRoutes = routes.filter((route, i, routes) => routes.indexOf(route) === i);

    this.menuItems = unqiueRoutes.map((r: any) => r = { name: r, route: this.availableRoutes[r] });
  }

  public get notifications() {
    return this.notificationsService.notifications;
  }

  public get new_notifications() {
    return this.notificationsService.notifications.filter(n => !n.isRead).length;
  }

  public async filterNotifications() {
    if (this.isLoggedIn) {
      await this.notificationsService.getOwnNotificationsFiltered();
    }
  }

  public async openNotification(notification) {
    // if (!notification.isRead) {
    await this.notificationsService.openNotification(notification);
    this.op.hide();
    this.filterNotifications()
    // }
  }

  public async switchLangByEvent(event) {
    //console.log(event, event.target.checked);
    if (event.target.checked == true) {
      this.switchLang("en")
    } else {
      this.switchLang("ro")
    }
    // this.translate.use(lang).subscribe(() => this.languageService.onLanguageChanged());
  }

  public async switchLang(lang: string) {
    this.translate.use(lang).subscribe(() => this.languageService.onLanguageChanged());
  }
}
