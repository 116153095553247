import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard_ implements CanActivate {
    constructor(private auth: AuthService,
        private router: Router) {

    }

    public async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {

        if ((next as any)._routerState.url == '/applications/view') return true;

        const authenticated = next.data['authenticated'] as boolean;

        const authState = await this.auth.authStateAsync;
        // console.log(authState,)
        const session = await this.auth.getSession();
        if (session?.student != null && !session?.isVerified) {
            await this.router.navigate(['/']);
        }
        if (authState === authenticated) {
            return true;
        }

        if (authenticated) {
            await this.router.navigate(['/'], { queryParams: { returnUrl: (next as any)._routerState.url, openLogin: true } });
        } else {
            await this.router.navigate(['/']);
        }

        return false;
    }
}
