import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private languageChanged = new Subject();

  languageChanged$ = this.languageChanged.asObservable();

  constructor() { }

  onLanguageChanged() {
    this.languageChanged.next();
  }
}
