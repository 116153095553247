import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobilityUsersComponent } from './mobility-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'apps/erasmus/src/app/material.module';
import { PrimeNgModule } from 'apps/erasmus/src/app/primeng.module';
import { CustomValidatorsModule } from '@upt/erasmus/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    MobilityUsersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PrimeNgModule,
    CustomValidatorsModule,
    TranslateModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    MobilityUsersComponent
  ],
})
export class MobilityUsersModule { }
