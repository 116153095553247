import { ActivatedRoute } from '@angular/router';
import { email } from './../../../../../../../../libs/shared/src/lib/custom-validators/email/validator';
import {
  CoreRole,
  Professor,
  AdministrativeStaff,
  ProfessorRole,
  ManagementRole,
  CommissionRole,
} from './../../../../../../../../libs/types/src/lib/erasmus/user.model';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AuthService, CfgService, HttpService } from '@upt/erasmus/shared';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'upt-mobility-users',
  templateUrl: './mobility-users.component.html',
  styleUrls: ['./mobility-users.component.scss'],
})
export class MobilityUsersComponent implements OnInit, OnChanges {
  public state = 'loading';

  @Input('coreRoleFilter') public coreRoleFilter: string;

  public viewMode: boolean;
  public professors: Professor[] = [];
  public administrativeStaff: AdministrativeStaff[] = [];

  public userSearched = '';
  public onlyAdministrativeStaff: boolean;

  public loggedUser: any = null;

  public showEditUserRolesDialog: boolean = false;
  public activeUser: Professor | AdministrativeStaff;

  public professorRoles;
  public managementRoles;
  public commissionRoles;
  public administrativeStaffRoles;

  constructor(
    public cfg: CfgService,
    public auth: AuthService,
    private httpService: HttpService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    public route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.loggedUser = await this.auth.getSession();
    await this.initList();

    this.professorRoles = [...this.cfg.professorRoles];
    this.managementRoles = [...this.cfg.managementRoles];
    this.commissionRoles = [...this.cfg.commissionRoles];
    this.administrativeStaffRoles = [...this.cfg.administrativeStaffRoles];

    this.checkInput();
    this.adjustColumns();
  }

  ngOnChanges() {
    this.checkInput();
  }

  async initList() {
    const users = await this.httpService.getAllStaffUsers();
    this.professors = users
      .filter((u) => u.professor != null)
      .map((u) => {
        if (u.professor.roles == undefined)
          return {
            ...u,
            professor: {
              ...u.professor,
              roles: {
                professorId: u.professor.id,
                professorRole: '-',
                managementRoles: '-',
              },
            },
          };
        else if (
          u.professor.roles.managementRole == undefined &&
          u.professor.roles.professorRole != undefined
        )
          return {
            ...u,
            professor: {
              ...u.professor,
              roles: {
                professorId: u.professor.id,
                professorRole: u.professor.roles.professorRole,
                managementRole: '-',
              },
            },
          };
        else if (
          u.professor.roles.professorRole == undefined &&
          u.professor.roles.managementRole != undefined
        )
          return {
            ...u,
            professor: {
              ...u.professor,
              roles: {
                professorId: u.professor.id,
                professorRole: '-',
                managementRole: u.professor.roles.managementRole,
              },
            },
          };
        return u;
      });
    this.administrativeStaff = users
      .filter((u) => u.administrativeStaff != null)
      .map((u) => {
        if (u.administrativeStaff.administrativeStaffRoles.length > 0) {
          return {
            ...u,
            administrativeStaff: {
              ...u.administrativeStaff,
              administrativeStaffRoles:
                u.administrativeStaff.administrativeStaffRoles.map(
                  (r: any) => r.administrativeStaffRole
                ),
            },
          };
        }
        return u;
      });

    this.state = 'ready';
  }

  checkInput() {
    this.onlyAdministrativeStaff = this.coreRoleFilter != undefined && this.coreRoleFilter == CoreRole[CoreRole.AdministrativeStaff];
  }

  adjustColumns() {
    this.route.queryParams.subscribe(params => {
      if (params.viewMode == undefined) {
        this.viewMode = true;
      } else {
        this.viewMode = params.viewMode == 'true';
      }
    });

    const actionsColumn = { field: 'actions', name: 'Actiuni', center: true, width: '120px', type: '' };
    if (!this.viewMode) {
      this.proffesorsColumns.push(actionsColumn);
      this.administrativeStaffColumns.push(actionsColumn);
    }
  }

  public get isLoading(): boolean {
    return this.state === 'loading';
  }

  public get isSaving(): boolean {
    return this.state === 'saving';
  }

  public proffesorsColumns = [
    { field: '#', name: '#', width: '50px', center: true, type: 'AAA' },
    { field: 'email', name: 'Email', width: '210px' },
    { field: 'name', name: 'Nume, Prenume', width: '180px' },
    { field: 'professorRole', name: 'ProfessorRole', width: '200px' },
    { field: 'managementRole', name: 'ManagementRole', width: '200px' },
    { field: 'commissionRoles', name: 'CommissionRoles', width: '200px' },
    { field: 'department', name: 'Departament' },
  ];

  public administrativeStaffColumns = [
    { field: '#', name: '#', width: '50px', center: true, type: 'AAA' },
    { field: 'email', name: 'Email', width: '210px' },
    { field: 'name', name: 'Nume, Prenume', width: '180px' },
    {
      field: 'administrativeStaffRole',
      name: 'AdministrativeStaffRoles',
      width: '200px',
    },
    { field: 'commissionRoles', name: 'CommissionRoles', width: '200px' },
    { field: 'department', name: 'Departament' }
  ];

  openEditUserRolesDialog(user) {
    this.showEditUserRolesDialog = true;
    this.activeUser = JSON.parse(JSON.stringify(user));
  }

  closeEditUserRolesDialog() {
    this.showEditUserRolesDialog = false;
  }

  async save(user) {
    if (user.professor != null) {
      user.professor.roles.managementRole =
        user.professor.roles.managementRole == '-'
          ? (user.professor.roles.managementRole = null)
          : user.professor.roles.managementRole;
      user.professor.roles.professorRole =
        user.professor.roles.professorRole == '-'
          ? (user.professor.roles.professorRole = null)
          : user.professor.roles.professorRole;
    }
    if (user.administrativeStaff != null) {
      user.administrativeStaff.administrativeStaffRoles =
        user.administrativeStaff.administrativeStaffRoles.map((r: any) => ({
          administrativeStaffRole: r,
        }));
    }

    this.state = 'saving';
    await this.httpService.updateUserRoles(user).then(async () => {
      this.showEditUserRolesDialog = false;
      this.messageService.add({ severity: 'success', detail: 'User successfully updated', summary: 'Success', life: 5000 });
      await this.initList();
      this.state = 'ready';
    }, error => {
      this.messageService.add({ severity: 'error', detail: error.error.message, life: 5000 });
      this.state = 'ready';
    });
  }
}
