import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'submit-button',
    templateUrl: './submit-button.component.html',
    styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent implements OnInit {

    @Input()
    public text: string;

    @Input()
    public loading: boolean;

    @Input()
    public color: string;

    @Input()
    public disabled: boolean;

    @Output()
    public click: EventEmitter<any> = new EventEmitter();

    public ngOnInit() {
    }

    public get displayText() {
        return this.text || 'Submit';
    }

    public get isLoading() {
        return this.loading;
    }

    public clicked($event: any): void {
        $event.stopPropagation();
        if (!this.loading) {
            this.click.emit($event);
        }
    }
}
