import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {
  @Input("input") public input: HTMLInputElement | any= null;
  constructor() { }

  ngOnInit(): void {
  }

}
