<p-dialog [header]="'Login' | translate" [modal]="true" [draggable]="false" [resizable]="false" [(visible)]="display">
    <form #loginUserForm="ngForm" novalidate class="login-width">

        <div mat-dialog-content>
            <!--  -->
            <mat-form-field class="full-width" autocomplete="off">
                <input autocomplete="off" matInput [(ngModel)]="form.email" type='text' email required name="email"
                    placeholder="Login email" #email="ngModel" />
                <mat-error>
                    <div *ngIf="!email?.valid && (email?.dirty || email?.touched)" class="error">
                        <div *ngIf="email.errors.required">
                            Email is required
                        </div>
                        <div *ngIf="email.errors.email">
                            Invalid Email Address
                        </div>
                    </div>
                </mat-error>
            </mat-form-field>
            <!-- <mat-hint>{{config.hint}}</mat-hint> -->

          <!--    <mat-form-field class="full-width" autocomplete="off" style="width: 100%;">
                <mat-select placeholder="Email" [(ngModel)]="form.email" #email="ngModel" required name="smester">
                    <mat-option
                        *ngFor="let email of ['andrei.voicu@student.upt.ro', 'radu.bals@student.upt.ro', 'paul.majar@student.upt.ro', 'claudia.stoian@upt.ro', 'claudiu.albulescu@upt.ro', 'ciprian.chirila@upt.ro', 'cristian.blidariu@upt.ro', 'mihai.micea@upt.ro', 'florin.dragan@upt.ro', 'raul.robu@upt.ro', 'miriana.milosavlevici@upt.ro', 'diana.bals-diaconescu@upt.ro']"
                        [value]="email">
                        {{email}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    <input-error [input]="email"></input-error>
                </mat-error>
            </mat-form-field>
 -->
            <mat-form-field class="full-width" autocomplete="off">
                <input autocomplete="off" matInput type='password' required [(ngModel)]="form.password" name="password"
                    [placeholder]="'Password' | translate" minlength="6" #password="ngModel" />
                <mat-error>
                    <input-error [input]="password"></input-error>
                </mat-error>
            </mat-form-field>

        </div>
        <div mat-dialog-actions class="d-flex flex-column mt-2">
            <!-- <p class="text-center">
                {{'GDPR' | translate}}
            </p> -->
            <div class="d-flex justify-content-center">
                <submit-button (click)="submit()" [loading]="submitting" [text]="'Login' | translate"
                    [disabled]="!loginUserForm.valid">
                </submit-button>
            </div>
        </div>
        <p-messages [(value)]="messages" [escape]="false" *ngIf="messages.length > 0"> </p-messages>
    </form>

</p-dialog>